import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import initAnchorLinks from "../../modules/anchor-links.ts";
import initScrollSpy from "../../modules/scroll-spy.ts";
const Header = () => {
  useEffect(() => {
    // Инициализируем якорные ссылки с плавной прокруткой
    initAnchorLinks(document, window);
    initAnchorLinks(document, window, 'a[data-anchor^="#"]');
    initScrollSpy("section[id]", 'a[data-anchor^="#"]');
  }, []);
  return (
    <header className="header">
      <div className="container">
        <div className="header__wrapper">
          <div className="header__desktop">
            <div className="header__links">
              <nav className="header__nav">
                <Link
                  to="/"
                  className="header__logo-link"
                  onClick={(e) => {
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                >
                  <div className="header__logo">
                    <img src="/images/logo.svg" alt="" />
                  </div>
                  <p className="header__nav-item">Грамота от Кузьмича</p>
                </Link>

                <Link
                  to="/about"
                  className="header__nav-item"
                  onClick={(e) => {
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                >
                  Обо мне
                </Link>
              </nav>
            </div>

            <div className="header__anchor">
              <Link
                to="https://devray.getcourse.ru/cms/system/login?required=true"
                target="_blank"
                rel="noopener noreferrer"
                className="header__nav-item"
              >
                Войти
              </Link>
              <Link
                to="/#form"
                data-anchor="true"
                className="header__anchor-item"
              >
                Записаться в школу
              </Link>
            </div>
          </div>

          <div className="header__mobile">
            <div className="header__links">
              <nav className="header__nav">
                <Link to="/" className="header__logo-link">
                  <p className="header__nav-item">Грамота от Кузьмича</p>
                </Link>
                <Link
                  to="https://devray.getcourse.ru/cms/system/login?required=true"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="header__nav-item"
                >
                  Войти
                </Link>
              </nav>
            </div>

            <div className="header__anchor">
              <Link to="/about" className="header__nav-item">
                Обо мне
              </Link>
              {/* <div className="header__divider"></div>
              <Link
                to="/#form"
                data-anchor="true"
                className="header__anchor-item"
              >
                Записаться
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
