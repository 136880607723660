const planData = {
  data: [
    {
      id: 1,
      attributes: {
        title: "Основы Java",
        period: "1 неделя",
        theory:
          "Для начала надо въехать что происходит. Java&nbsp;&mdash; это&nbsp;ж язык? Значит на&nbsp;нем надо научиться говорить. Роман мы&nbsp;сразу не&nbsp;сядем писать&nbsp;&mdash; так, слегка разомнемся: Hello world, примитивные типы, арифметика, разберемся с&nbsp;методами.",
        practice:
          "Формируем фундамент и&nbsp;пишем несложные функции на&nbsp;понимание операторов языка. Берегись&nbsp;&mdash; нас ждут калькуляторы, конвертеры и&nbsp;всякие утилиты-полезности!",
      },
    },
    {
      id: 2,
      attributes: {
        title: "Объектно-ориентированное программирование",
        period: "2 неделя",
        theory:
          "Эта неделя про ООП&nbsp;&mdash; когда язык программирования превращается в&nbsp;кубики лего, из&nbsp;которых можно собрать че&nbsp;угодно. Мы&nbsp;поговорим про классы, интерфейсы, иерархии и&nbsp;про то&nbsp;как можно красиво упорядочить хаос. А&nbsp;еще поработаем с&nbsp;Git.",
        practice:
          "Разрабатываем свою иерархию классов и&nbsp;&laquo;коробочку&raquo; для их&nbsp;хранения c&nbsp;применением принципов ООП&nbsp;&mdash; инкапсуляция, наследование, полиморфизм и&nbsp;абстракция.",
      },
    },

    {
      id: 3,
      attributes: {
        title: "Продвинутая Java",
        period: "3 неделя",
        theory:
          "Тащи шампуры, самое мясо разработки тут! Когда классов и&nbsp;объектов становится много&nbsp;&mdash; надо научиться их&nbsp;группировать и&nbsp;работать со&nbsp;всеми сразу. Будет много полезностей&nbsp;&mdash; Collections, Stream API, рефлексия и&nbsp;аннотации.",
        practice:
          "Напишем первое приложение&nbsp;&mdash; имитирующее работу ресторана. Здесь будет интенсивное использование коллекций!",
      },
    },

    {
      id: 4,
      attributes: {
        title: "Контроль версий и сборка",
        period: "4 неделя",
        theory:
          "Про разработку чего-то уже существующего говорят&nbsp;&mdash; &laquo;изобретать велосипед&raquo;. Мы&nbsp;не&nbsp;будем его изобретать, вместо этого посмотрим как подключать и&nbsp;использовать чужие библиотеки, чтобы собирать более сложные проекты и&nbsp;экономить свое время.",
        practice:
          "А тут у вас прокачается фантазия&nbsp;&mdash; мы собирем свой первый проект с&nbsp;зависимостями&nbsp;&mdash; и&nbsp;напишем приложение-генератор PDF-сертификата выпускника, почти как тот, который можно получить по окончании курса :)",
      },
    },

    {
      id: 5,
      attributes: {
        title: "Введение в автотестирование",
        period: "5 неделя",
        theory:
          "Когда готовишь бутер&nbsp;&mdash; важно убедиться что хлеб не черствый, колбаска свежая, а бутерброд не шире лица. Это&nbsp;&mdash; требования к бутерброду. А мы будем проверять что программа соответствует требованиям&nbsp;&mdash; с&nbsp;помощью автотестов! Но&nbsp;для начала&nbsp;&mdash; научимся их писать с&nbsp;TestNG.",
        practice:
          "Пишем набор юнит-тестов на Java-приложение и&nbsp;организуем тесты в группы и&nbsp;сьюты. Приложение мало просто проверить&nbsp;&mdash; это еще нужно сделать со&nbsp;стилем!",
      },
    },

    {
      id: 6,
      attributes: {
        title: "Тестирование WEB на Selenide",
        period: "6 неделя",
        theory:
          "Шекспир говорил что весь мир&nbsp;&mdash; сайт.. или&nbsp;театр? Возможно я напутал и&nbsp;это была цитата Джейсона Стетхема. Сайтов много, каждый требует любви, внимания и&nbsp;тестов. Selenide&nbsp;&mdash; специализированный инструмент для тестирования сайтов, познакомимся с&nbsp;ним и&nbsp;научимся взаимодействовать с&nbsp;веб-страничкой из кода.",
        practice:
          "Разрабатываем фреймворк, таинственные PageObjects и&nbsp;пачку UI тестов на&nbsp;примере реального CRM сервиса (он кстати не в курсе что мы его тестируем, приятный сюрприз)",
      },
    },

    {
      id: 7,
      attributes: {
        title: "Тестирование API на Rest Assured",
        period: "7 неделя",
        theory:
          "Модная теория заговора&nbsp;&mdash; сайты всего лишь марионетки и&nbsp;отрисовывают только то, что говорит им API! На этой неделе мы обсудим трехзвенную архитектуру, HTTP запросы и&nbsp;потестируем слой API с&nbsp;помощью RestAssured.",
        practice:
          "Будет ассорти заданий&nbsp;&mdash; берем какое ближе душе! Тестируем API сервиса погоды, новостей или финансовых данных. Три варианта&nbsp;&mdash; как у витязя на распутье с&nbsp;картины Васнецова.",
      },
    },

    {
      id: 8,
      attributes: {
        title: "CI, настройка отчетности",
        period: "8 неделя",
        theory:
          "А как посмотреть чего там автотесты наавтотестировали&nbsp;&mdash; отчеты, результаты, выводы? Чтоб они появлялись&nbsp;&mdash; добавим в наш проект Allure. А что бы они появлялись автоматически&nbsp;&mdash; добавим щепотку автоматизации и&nbsp;настроим&nbsp;CI. Gitlab&nbsp;CI. (типа Бонд, Джеймс Бонд, ну&nbsp;че мне шутку приходится объяснять)",
        practice:
          "Собираем свой собственный CI с&nbsp;автозапуском тестов и&nbsp;построением красивых отчетов&nbsp;&mdash; венец наших кропотливых трудов и&nbsp;недель гугления.",
      },
    },
  ],
  meta: {},
};
export default planData;
