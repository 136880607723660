import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import Webcam from "react-webcam";

const Footer = () => {
  useEffect(() => {
    let date = new Date().getFullYear();
    const currentYear = document.querySelector(".current-year");
    if (currentYear) currentYear.innerHTML = date;
  }, []);

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__wrapper">
          <div className="footer__desktop">
            <div className="footer__row">
              <div className="footer__links">
                <nav className="footer__nav">
                  <Link
                    to="/offer"
                    className="footer__nav-item"
                    onClick={(e) => {
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    }}
                  >
                    ОФЕРТА КУЗЬМИЧА
                  </Link>
                  <Link
                    to="/policy"
                    className="footer__nav-item"
                    onClick={(e) => {
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    }}
                  >
                    ПОЛИТИКА ВАШЕЙ
                    <br /> КОНФИДЕНЦИАЛЬНОСТИ
                  </Link>
                  <Link
                    to="https://t.me/kiira"
                    target="_blank"
                    className="footer__nav-item"
                  >
                    А ДИЗАЙН КИРЫ
                    <br /> ТУГОЛУКОВСКОЙ
                  </Link>
                </nav>
              </div>

              <div className="footer__links footer__links-mail">
                <Link
                  to="mailto:info@devray.ru"
                  className="footer__anchor-item"
                >
                  info@devray.ru
                </Link>
              </div>
            </div>

            <div className="footer__row">
              <span className="current-year">2023</span>
              <div className="footer__icons">
                <Link
                  to="https://t.me/java_ot_kuzmicha"
                  target="_blank"
                  className="footer__icon"
                >
                  <svg
                    width="27"
                    height="23"
                    viewBox="0 0 27 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.88819 20.7215L10.2965 14.5528L21.4965 4.46111C21.9924 4.00903 21.3944 3.79028 20.7382 4.18403L6.91319 12.9194L0.934021 11.0236C-0.349313 10.659 -0.363896 9.76944 1.22569 9.12778L24.5153 0.144447C25.5799 -0.336802 26.6007 0.406948 26.1924 2.04028L22.2257 20.7215C21.9486 22.0486 21.1465 22.3694 20.0382 21.7569L14.0007 17.2944L11.0986 20.109C10.7632 20.4444 10.4861 20.7215 9.88819 20.7215Z"
                      fill="#8B8F92"
                    />
                  </svg>
                </Link>
                <Link
                  to="https://www.youtube.com/@u-kuzmicha"
                  target="_blank"
                  className="footer__icon"
                >
                  <svg
                    width="26"
                    height="19"
                    viewBox="0 0 26 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.5209 0H5.3791C2.4083 0 0 2.40968 0 5.38217V12.9558C0 15.9283 2.4083 18.338 5.3791 18.338H20.5209C23.4917 18.338 25.9 15.9283 25.9 12.9558V5.38217C25.9 2.40968 23.4917 0 20.5209 0ZM16.8831 9.53746L9.80074 12.9172C9.61202 13.0073 9.39404 12.8696 9.39404 12.6604V5.68966C9.39404 5.47751 9.61775 5.34001 9.80686 5.43591L16.8892 9.02693C17.0997 9.13368 17.0961 9.43583 16.8831 9.53746Z"
                      fill="#8B8F92"
                    />
                  </svg>
                </Link>
              </div>
            </div>
          </div>

          <div className="footer__mobile">
            <div className="footer__links">
              <nav className="footer__nav">
                <Link
                  to="/policy"
                  className="footer__nav-item"
                  onClick={(e) => {
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                >
                  ПОЛИТИКА ВАШЕЙ
                  <br /> КОНФИДЕНЦИАЛЬНОСТИ
                </Link>
                <Link
                  to="/offer"
                  className="footer__nav-item"
                  onClick={(e) => {
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                >
                  ОФЕРТА КУЗЬМИЧА
                </Link>

                <Link
                  to="https://t.me/kiira"
                  target="_blank"
                  className="footer__nav-item"
                >
                  А ДИЗАЙН КИРЫ
                  <br /> ТУГОЛУКОВСКОЙ
                </Link>
              </nav>
              <div className="footer__links-mail">
                <span className="current-year">2023</span>
              </div>
            </div>

            <div className="footer__questions">
              <div className="footer__questions-links">
                <Link
                  to="https://t.me/NeonAether"
                  target="_blank"
                  className="footer__anchor-item link"
                >
                  ОСТАЛИСЬ ВОПРОСЫ? ПИШИ В ТЕЛЕГРАМ
                </Link>
                <Link
                  to="mailto:info@devray.ru"
                  className="footer__anchor-item link"
                >
                  info@devray.ru
                </Link>
              </div>

              <div className="footer__icons">
                <Link
                  to="https://t.me/java_ot_kuzmicha"
                  target="_blank"
                  className="footer__icon"
                >
                  <svg
                    width="27"
                    height="23"
                    viewBox="0 0 27 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.88819 20.7215L10.2965 14.5528L21.4965 4.46111C21.9924 4.00903 21.3944 3.79028 20.7382 4.18403L6.91319 12.9194L0.934021 11.0236C-0.349313 10.659 -0.363896 9.76944 1.22569 9.12778L24.5153 0.144447C25.5799 -0.336802 26.6007 0.406948 26.1924 2.04028L22.2257 20.7215C21.9486 22.0486 21.1465 22.3694 20.0382 21.7569L14.0007 17.2944L11.0986 20.109C10.7632 20.4444 10.4861 20.7215 9.88819 20.7215Z"
                      fill="#8B8F92"
                    />
                  </svg>
                </Link>
                <Link
                  to="https://www.youtube.com/@u-kuzmicha"
                  target="_blank"
                  className="footer__icon"
                >
                  <svg
                    width="26"
                    height="19"
                    viewBox="0 0 26 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.5209 0H5.3791C2.4083 0 0 2.40968 0 5.38217V12.9558C0 15.9283 2.4083 18.338 5.3791 18.338H20.5209C23.4917 18.338 25.9 15.9283 25.9 12.9558V5.38217C25.9 2.40968 23.4917 0 20.5209 0ZM16.8831 9.53746L9.80074 12.9172C9.61202 13.0073 9.39404 12.8696 9.39404 12.6604V5.68966C9.39404 5.47751 9.61775 5.34001 9.80686 5.43591L16.8892 9.02693C17.0997 9.13368 17.0961 9.43583 16.8831 9.53746Z"
                      fill="#8B8F92"
                    />
                  </svg>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
