import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/swiper-bundle.min.css";

const FeedbackItem = (props) => {
  const { attributes } = props;
  const { name, post, feedback, image } = attributes;

  return (
    <>
      <div className="feedback__item">
        <div className="feedback__row feedback__row-1">
          <p
            className="feedback__text"
            dangerouslySetInnerHTML={{ __html: feedback }}
          ></p>
        </div>

        <div className="feedback__row feedback__row-2">
          <div className="feedback__column">
            <img
              className="feedback__img"
              src={`/images/feedbacks/${image}`}
              alt=""
            />
          </div>

          <div className="feedback__column">
            <p
              className="feedback__name"
              dangerouslySetInnerHTML={{ __html: name }}
            ></p>
            <p
              className="feedback__post"
              dangerouslySetInnerHTML={{ __html: post }}
            ></p>
          </div>
        </div>
      </div>
      <div className="gutter-sizer"></div>
    </>
  );
};

export default FeedbackItem;
