import React, { useState, useEffect, Fragment } from "react";
import { ReactDOM, createPortal } from "react-dom";
import { useHistory, useLocation } from "react-router-dom";
import { Buffer } from "buffer";
// import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import Masonry from "masonry-layout";
import "swiper/swiper-bundle.min.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import Footer from "../../components/footer";
import Screenshot from "../../components/screenshot";

import PlanItem from "../../components/planItem";
import planData from "../../info/plan";
import FeedbackItem from "../../components/feedbackItem";
import FormFull from "../../components/form-full";
import FormRegister from "../../components/form-register";
import FormBeginner from "../../components/form-beginner";
import FormSurvivor from "../../components/form-survivor";
import feedbacks from "../../info/feedbacks";
import initAnchorLinks from "../../modules/anchor-links.ts";
import initScrollSpy from "../../modules/scroll-spy.ts";

import "../../static/scss/home.scss";
import "../../static/scss/layout.scss";

function Home() {
  const [openFeedbacks, setOpenFeedbacks] = useState(false);
  const [mob, setMob] = useState(false);

  useEffect(() => {
    let date = new Date().getFullYear();
    const currentYear = document.querySelector(".current-year");
    if (currentYear) currentYear.innerHTML = date;
  }, []);

  useEffect(() => {
    // Инициализируем якорные ссылки с плавной прокруткой
    initAnchorLinks(document, window);
    initAnchorLinks(document, window, 'a[data-anchor^="#"]');
    initScrollSpy("section[id]", 'a[data-anchor^="#"]');
  }, []);

  useEffect(() => {
    const grid = document.querySelector(".adaptive-list");
    function startMasonry() {
      const masonry = new Masonry(grid, {
        itemSelector: ".feedback__item",
        gutter: ".gutter-sizer",
        percentPosition: true,
      });
    }

    setTimeout(startMasonry, 300);
  }, [openFeedbacks]);

  useEffect(() => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      ) &&
      window.innerWidth < 768
    ) {
      setMob(true);
    }
  });

  return (
    <>
      <div className="home">
        <header className="header">
          <div className="container">
            <div className="header__wrapper">
              <div className="header__desktop">
                <div className="header__links">
                  <nav className="header__nav">
                    <Link
                      to="/"
                      className="header__logo-link"
                      onClick={(e) => {
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      }}
                    >
                      <div className="header__logo">
                        <img src="/images/logo.svg" alt="" />
                      </div>
                      <p className="header__nav-item">Грамота от Кузьмича</p>
                    </Link>

                    <Link
                      to="/about"
                      className="header__nav-item"
                      onClick={(e) => {
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      }}
                    >
                      Обо мне
                    </Link>
                  </nav>
                </div>

                <div className="header__anchor">
                  <Link
                    to="https://devray.getcourse.ru/cms/system/login?required=true"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="header__nav-item"
                  >
                    Войти
                  </Link>
                  <a
                    href="#form"
                    data-anchor="true"
                    className="header__anchor-item"
                  >
                    Записаться в школу
                  </a>
                </div>
              </div>

              <div className="header__mobile">
                <div className="header__links">
                  <nav className="header__nav">
                    <Link to="/" className="header__logo-link">
                      <p className="header__nav-item">Грамота от Кузьмича</p>
                    </Link>
                    <Link
                      to="https://devray.getcourse.ru/cms/system/login?required=true"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="header__nav-item"
                    >
                      Войти
                    </Link>
                  </nav>
                </div>

                <div className="header__anchor">
                  <Link to="/about" className="header__nav-item">
                    Обо мне
                  </Link>
                  {/* <div className="header__divider"></div>
                  <a
                    href="#form"
                    data-anchor="true"
                    className="header__anchor-item"
                  >
                    Записаться
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </header>

        <main className="main">
          <div className="container">
            <div className="main__wrapper">
              <div className="main__block">
                <h1 className="main__title">
                  Онлайн школа автотестирования
                  <br /> на Java
                </h1>
                <div className="main__text">
                  <p className="regular">
                    Здесь ты&nbsp;научишься писать на&nbsp;Java и&nbsp;работать
                    с&nbsp;ключевыми библиотеками для автотестирования API
                    и&nbsp;веб-приложений.
                  </p>
                  <p className="regular">
                    Через 3&nbsp;месяца ты&nbsp;будешь уметь создавать проекты
                    по&nbsp;автотестированию приложений с&nbsp;нуля
                    и&nbsp;соответствовать всем требованиям рынка.
                  </p>
                </div>
              </div>

              <div className="main__block info">
                <div className="info__row info__row-1">
                  <div className="info__row-item">
                    Старт
                    <br /> 3 октября
                  </div>
                  <div className="info__row-item">~3 месяца</div>
                  <div className="info__row-item green-text">от 19 500 ₽</div>
                  <a
                    href="#form"
                    data-anchor="true"
                    className="info__row-item green-block"
                  >
                    ХОЧУ УЧИТЬСЯ!
                  </a>
                </div>

                <div className="info__row info__row-2">
                  <div className="info__row-item large">
                    <h2>Формат школы</h2>
                    <p>
                      Занятия проходят онлайн в&nbsp;группах по&nbsp;10-12
                      студентов в&nbsp;виде живого и&nbsp;бодрого урока
                      с&nbsp;общением. Будем обмениваться знаниями, опытом,
                      советами и&nbsp;мемами!
                    </p>
                  </div>

                  <div className="info__row-item">
                    <img src="/images/icon-zoom.svg" alt="zoom-icon" />
                    <p>
                      Занимаемся в&nbsp;Zoom по&nbsp;вторникам и&nbsp;четвергам
                      в&nbsp;20:00 по&nbsp;МСК.
                    </p>
                  </div>
                </div>
              </div>

              <div className="main__block section-3">
                <a
                  href="https://youtu.be/HAGWJCbFCME?si=WRGfmFUe39CJCzCc"
                  className="section-3__link"
                >
                  <div className="section-3__link-img">
                    <img
                      className="section-3__link-1 desktop"
                      src="/images/tv-icon.svg"
                      alt=""
                    />
                    <img
                      className="section-3__link-2 desktop"
                      src="/images/tv-icon-active.svg"
                      alt=""
                    />
                    <img
                      className="section-3__link-3 mobile"
                      src="/images/tv-mobile.svg"
                      alt=""
                    />
                  </div>
                  <p className="section-3__link-text link">
                    Посмотрите, как я преподаю
                  </p>
                </a>

                <h1 className="main__title">
                  Приготовься к&nbsp;приключению и&nbsp;наберись
                  отваги&nbsp;&mdash; будем учиться!
                </h1>
              </div>

              <div className="main__block plan">
                <div className="plan__wrapper">
                  {planData.data &&
                    planData.data.map((item, index) => (
                      <PlanItem
                        className={index === 0 ? "active" : ""}
                        key={item.id}
                        index={index}
                        mob={mob}
                        {...item}
                      />
                    ))}
                  <div className="screen">
                    <div className="info__row info__row-1">
                      <div className="screen__col">
                        <h2>
                          И&nbsp;в&nbsp;конце&nbsp;&mdash; диплом
                          с&nbsp;проектом, который обычно делают на реальной
                          работе
                        </h2>
                        <span>9-12 неделя</span>
                      </div>
                    </div>
                    <div className="info__row info__row-2">
                      <div className="screen__col">
                        <span>
                          Ну&nbsp;и&nbsp;конечно&nbsp;же&nbsp;&mdash;
                          сертификат.
                        </span>
                        <div className="screenshot__container">
                          <Screenshot mob={mob} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="main__block section-5">
                <h1 className="main__title">
                  Я&nbsp;преподаю уже 5&nbsp;лет и&nbsp;выпустил более
                  100&nbsp;студентов. Вот парочка их&nbsp;отзывов
                </h1>
                <Link
                  to="/about"
                  className="link"
                  onClick={(e) => {
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                >
                  Почитать чуть больше обо мне
                </Link>
              </div>

              <div className="main__block feedbacks">
                <div className="feedbacks__wrapper">
                  <div
                    className={`feedbacks__container ${
                      openFeedbacks ? "active" : ""
                    }`}
                  >
                    {!mob && (
                      <div className="feedbacks__list adaptive-list">
                        {feedbacks.data &&
                          feedbacks.data.map((item, index) => (
                            <FeedbackItem key={item.id} {...item} />
                          ))}
                      </div>
                    )}

                    {mob && (
                      <Swiper
                        className="feedbacks__list"
                        // install Swiper modules
                        // modules={[Navigation, Pagination, Scrollbar, A11y]}
                        spaceBetween={10}
                        slidesPerView={1.2}
                        autoHeight={true}
                        centeredSlides={true}
                        // scrollbar={{ draggable: true }}
                        // onSwiper={(swiper) => console.log(swiper)}
                        // onSlideChange={() => console.log("slide change")}
                      >
                        {feedbacks.data &&
                          feedbacks.data.map((item, index) => (
                            <SwiperSlide className="feedback__item">
                              <div className="feedback__row feedback__row-1">
                                <p
                                  className="feedback__text"
                                  dangerouslySetInnerHTML={{
                                    __html: item.attributes.feedback,
                                  }}
                                ></p>
                              </div>

                              <div className="feedback__row feedback__row-2">
                                <div className="feedback__column">
                                  <img
                                    className="feedback__img"
                                    src={`/images/feedbacks/${item.attributes.image}`}
                                    alt=""
                                  />
                                </div>

                                <div className="feedback__column">
                                  <p
                                    className="feedback__name"
                                    dangerouslySetInnerHTML={{
                                      __html: item.attributes.name,
                                    }}
                                  ></p>
                                  <p
                                    className="feedback__post"
                                    dangerouslySetInnerHTML={{
                                      __html: item.attributes.post,
                                    }}
                                  ></p>
                                </div>
                              </div>
                            </SwiperSlide>
                          ))}
                      </Swiper>
                    )}
                  </div>
                  {!openFeedbacks && (
                    <div
                      className="feedbacks__button link"
                      onClick={(e) => setOpenFeedbacks(!openFeedbacks)}
                    >
                      Посмотреть все отзывы
                    </div>
                  )}

                  <div className="feedbacks__stickers">
                    <div className="feedbacks__stickers-item">
                      <img
                        src="/images/sticker-1.png"
                        srcSet="/images/sticker-1@2x.png 2x"
                        alt=""
                      />
                    </div>

                    <div className="feedbacks__stickers-item">
                      <img
                        src="/images/sticker-2.png"
                        srcSet="/images/sticker-2@2x.png 2x"
                        alt=""
                      />
                    </div>
                    {openFeedbacks && (
                      <>
                        <div className="feedbacks__stickers-item">
                          <img
                            src="/images/sticker-3.png"
                            srcSet="/images/sticker-3@2x.png 2x"
                            alt=""
                          />
                        </div>

                        <div className="feedbacks__stickers-item">
                          <img
                            src="/images/sticker-4.png"
                            srcSet="/images/sticker-4@2x.png 2x"
                            alt=""
                          />
                        </div>

                        <div className="feedbacks__stickers-item">
                          <img
                            src="/images/sticker-5.png"
                            srcSet="/images/sticker-5@2x.png 2x"
                            alt=""
                          />
                        </div>

                        <div className="feedbacks__stickers-item">
                          <img
                            src="/images/sticker-6.png"
                            srcSet="/images/sticker-6@2x.png 2x"
                            alt=""
                          />
                        </div>

                        {/* <div className="feedbacks__stickers-item">
                          <img src="/images/sticker-7.png" srcSet="/images/sticker-7@2x.png 2x" alt="" />
                        </div> */}
                      </>
                    )}
                  </div>
                </div>
              </div>

              <section id="form" className="main__block form">
                <div className="form__container">
                  <div className="form__element">
                    {/* <FormFull />
                    <FormBeginner />
                    <FormSurvivor /> */}
                    <FormRegister />
                  </div>
                  <div className="form__info">
                    <div className="form__info-text">
                      <p>
                        Набор закрыт. Но&nbsp;можно записаться в&nbsp;список
                        ожидания.
                      </p>
                      <p>
                        Стоимость обучения&nbsp;&mdash;
                        от&nbsp;19&nbsp;500&nbsp;₽. Следите за обновлениями!
                      </p>
                    </div>

                    <div className="form__info-link">
                      <img src="/images/sticker-form.png" alt="" />
                      <Link
                        className="link"
                        onClick={(e) => {
                          e.preventDefault();
                          document
                            .querySelector(".form-popover")
                            .classList.toggle("show");
                        }}
                      >
                        пройти Тест на котика
                      </Link>
                      <div className="form-popover">
                        Мяу! Тест пока не готов, но мои лапки уже стучат по
                        клавиатуре!
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <a
                href="https://t.me/NeonAether"
                target="_blank"
                className="about__questions"
              >
                <div className="about__questions-wrapper">
                  <h1>Остались вопросы?</h1>

                  <svg
                    width="354"
                    height="80"
                    viewBox="0 0 354 80"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.04826 38.2156C2.33891 37.7877 1.41697 38.0158 0.989048 38.7252C0.561127 39.4345 0.789268 40.3565 1.49862 40.7844L3.04826 38.2156ZM353.762 26.6867C353.865 25.8647 353.282 25.1148 352.46 25.0117L339.065 23.3316C338.243 23.2285 337.493 23.8113 337.39 24.6333C337.287 25.4553 337.87 26.2052 338.692 26.3083L350.598 27.8017L349.105 39.7084C349.002 40.5304 349.585 41.2803 350.407 41.3834C351.229 41.4865 351.979 40.9037 352.082 40.0818L353.762 26.6867ZM1.49862 40.7844C47.8262 68.732 114.892 81.63 180.697 79.4992C246.492 77.3687 311.348 60.2028 353.194 27.6844L351.353 25.3156C310.198 57.2972 246.055 74.3813 180.6 76.5008C115.155 78.62 48.7206 65.768 3.04826 38.2156L1.49862 40.7844Z"
                      fill="#2D8CFF"
                    />
                    <path
                      d="M140.281 19.9426C137.264 14.1166 130.074 7.82307 113.902 10.8781"
                      stroke="#2D8CFF"
                      strokeWidth="3"
                      strokeLinecap="round"
                    />
                    <path
                      d="M223.773 11.5C223.773 17.1542 219.618 21.5 214.773 21.5C209.928 21.5 205.773 17.1542 205.773 11.5C205.773 5.8458 209.928 1.5 214.773 1.5C219.618 1.5 223.773 5.8458 223.773 11.5Z"
                      stroke="#2D8CFF"
                      strokeWidth="3"
                    />
                  </svg>

                  <p className="h1">Отвечу в&nbsp;телеге</p>
                </div>
              </a>
            </div>
          </div>

          <div className="main__bg">
            <div className="main__bg-1">
              <img
                srcSet="images/home-bg-1@2x.png 2x"
                src="images/home-bg-1.png"
                className="desktop"
                alt=""
              />

              <img
                srcSet="images/home-bg-mob@2x.png 2x"
                src="images/home-bg-mob.png"
                className="mobile"
                alt=""
              />
            </div>

            <div className="main__bg-2 desktop">
              <img
                srcSet="images/home-bg-2@2x.png 2x"
                src="images/home-bg-2.png"
                alt=""
              />
            </div>

            <div className="main__bg-text desktop">
              <p>А это Я — Кузьмич, создатель и ПРЕПОД ШКОЛЫ</p>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
}

export default Home;
