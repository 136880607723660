import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";

const PlanItem = (props) => {
  const { attributes, index, mob } = props;

  const { title, period, theory, practice } = attributes;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (mob) {
      setOpen(false);
    }
  }, [mob]);
  return (
    <>
      <div
        className={`plan__item ${open ? "active" : ""} ${
          index === 0 ? "first" : ""
        }`}
        onClick={(e) => setOpen(!open)}
      >
        <div className="plan__row plan__row-1">
          <h2 dangerouslySetInnerHTML={{ __html: title }}></h2>
          <span dangerouslySetInnerHTML={{ __html: period }}></span>
        </div>

        <div
          className={`plan__row plan__row-2 ${open ? "active" : ""} ${
            index === 0 ? "first" : ""
          }`}
        >
          <div className="plan__column">
            <span>Теория</span>
            <p dangerouslySetInnerHTML={{ __html: theory }}></p>
          </div>

          <div className="plan__column">
            <span>Практика</span>
            <p dangerouslySetInnerHTML={{ __html: practice }}></p>
          </div>
        </div>
      </div>
    </>
  );
};

export default PlanItem;
