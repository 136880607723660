export default function initScrollSpy(sectionsSelector: string, linksSelector: string): void {
	const document = window.document;
	const sections = document.querySelectorAll(sectionsSelector);
	const anchorLinks = document.querySelectorAll(linksSelector);
	if (sections.length > 0 && anchorLinks.length > 0) {
		processScroll(sections, anchorLinks);
		document.addEventListener('scroll', () => processScroll(sections, anchorLinks));
	}
}

function processScroll(sections: NodeListOf<Element>, links: NodeListOf<Element>): void {
	let found = null;
	// Ищем сначала полностью вмещаемые на странице элементы
	sections.forEach(section => {
		if (isScrolledIntoView(section, false)) {
			found = section;
		}
	});
	// Если не нашли, ищем частично вмещаемые на странице элементы
	if (found === null) {
		sections.forEach(section => {
			if (isScrolledIntoView(section, true)) {
				found = section;
			}
		});
	}
	// Нашли элемент, подсвечиваем соответствующую ссылку
	if (found) {
		links.forEach(link => {
			link.classList.remove('active');
			link.parentElement.classList.remove('target');
		});
		const foundId = found.getAttribute('id');
		links.forEach(link => {
			let href = link.getAttribute('data-anchor') || '';
			if (!href.match(/^#/)) {
				href = link.getAttribute('href');
			}
			if (href.replace(/^#/, '') === foundId) {
				link.classList.add('active');
				link.parentElement.classList.add('target');
			}
		});
	}
}

function isScrolledIntoView(element: Element, isPartial: boolean): boolean {
	const rect = element.getBoundingClientRect();
	const elemTop = rect.top;
	const elemBottom = rect.bottom;
	if (isPartial) {
		// Partially visible elements return true
		return elemTop < window.innerHeight && elemBottom >= 0;
	}
	// Only completely visible elements return true
	return (elemTop >= 0) && (elemBottom <= window.innerHeight);
}
