import React from 'react';
import { BrowserRouter as Router, useRoutes } from "react-router-dom";
import Home from "./pages/home";
import About from "./pages/about";
import Politics from "./pages/politics";
import Offer from "./pages/offer";

function App() {
  const AppWrapper = () => {
    let routes = useRoutes([
      { path: "/", element: <Home /> }, 
      { path: "/about", element: <About /> }, 
      { path: "/policy", element: <Politics /> }, 
      { path: "/offer", element: <Offer /> }, 
    ]);
    return routes;
  };
  return (
    <Router>
      <AppWrapper />
    </Router>
  );
}

export default App;
