import React, { useState, useEffect, Fragment } from "react";
import ReactDOM from "react-dom";
import { useHistory, useLocation } from "react-router-dom";

// import { useHistory, useLocation } from "react-router";

import { Link } from "react-router-dom";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { iframe } from "../../iframe";

import "../../static/scss/home.scss";
import "../../static/scss/layout.scss";

function Politics() {
  const location = useLocation();

  useEffect(() => {
    function setIframe() {
      const form = document.querySelector(".form__element");
      if (iframe && location.pathname == "/policy") {
        form.appendChild(iframe);
      }
    }

    setTimeout(setIframe, 300);
  }, [location]);

  return (
    <div className="politics-page">
      <Header />

      <main className="politics">
        <div className="container">
          <div className="politics__wrapper">
            <div className="politics__block">
              <h2>Политика обработки персональных данных</h2>
              <p>
                Настоящая политика обработки персональных данных (далее —
                «Политика») издана и применяется [**] (далее «Оператор»)  в
                соответствии с п. 2 ч. 1 ст. 18.1 Федерального закона от
                27.07.2006 № 152-ФЗ «О персональных данных».
              </p>

              <ol>
                <li className="li-title">1. Общие положения</li>
                <li>
                  1.1. Настоящая Политика разъясняет порядок сбора, хранения и
                  защиты персональных данных Клиентов, которые решили
                  воспользоваться услугами Оператора.
                </li>
                <li>
                  1.2. Клиенты дают согласие на обработку персональных данных в
                  соответствии с настоящей Политикой путем проставления
                  чек-бокса «Согласен», а также при регистрации Личного кабинета
                  на Сайте Оператора.
                </li>
                <li>
                  1.3. Просим Вас внимательно ознакомиться с Политикой, это
                  поможет Вам понять область её применения и принять осознанное
                  решение о предоставлении Ваших Персональных данных. В случае
                  несогласия с этими условиями Клиент должен воздержаться от
                  услуг, предоставляемых Оператором.
                </li>
                <li>
                  1.4. Настоящая Политика может быть изменена, заменена или
                  удалена в любое время без предварительного уведомления.
                  Рекомендуется периодически просматривать эту страницу для
                  получения последней информации о Политике Оператора.
                </li>
              </ol>

              <ol>
                <li className="li-title">
                  2. Категории субъектов, персональные данные которых
                  обрабатываются:
                </li>
                <li>
                  2.1. Клиенты, состоящие в договорных отношениях с Оператором,
                  и их законные представители.
                </li>
              </ol>

              <ol>
                <li className="li-title">
                  3. Цели обработки персональных данных Клиентов
                </li>
                <li>
                  Оператор обрабатывает персональные данные Клиентов в следующих
                  целях:
                </li>
                <li>
                  3.1. Оказания Оператором услуг, согласно заключенному между
                  Оператором и Клиентом договору;
                </li>
                <li>
                  3.2. Установления с Клиентом обратной связи, включая
                  направление уведомлений, касающихся порядка оказания услуг,
                  обработку запросов и заявок от Клиента.
                </li>
              </ol>

              <ol>
                <li className="li-title">
                  4. Категории персональных данных, которые обрабатывает
                  Оператор
                </li>
                4.1. В рамках достижения целей обработки, предусмотренных
                настоящей Политикой, Оператор осуществляет обработку следующих
                категорий персональных данных Клиентов:
                <ul>
                  <li>фамилия, имя и отчество;</li>
                  <li>адрес электронной почты;</li>
                  <li>номер мобильного телефона.</li>
                </ul>
              </ol>

              <ol>
                <li className="li-title">
                  5. Способы и условия обработки персональных данных Клиентов
                </li>
                <li>
                  5.1. Оператор осуществляет автоматизированную и
                  неавтомати-зированную обработку персональных данных Клиентов.
                </li>
                <li>
                  5.2. В отношении персональной информации Клиентов сохраняется
                  ее конфиденциальность, кроме случаев добровольного
                  предоставления Клиентом информации о себе для общего доступа
                  неограниченному кругу лиц.
                </li>
                <li>
                  5.3. Оператор раскрывает информацию о Клиентах третьим лицам
                  исключительно в тех случаях, когда есть обязанность это
                  сделать в соответствии с действующим законодательством,
                  запросами государственных органов, судебным разбирательством,
                  постановлением суда или судебным процессом.
                </li>
                <li>
                  5.4. Обработка, в том числе и хранение, персональных данных
                  Клиентов осуществляется до момента отказа Клиента от услуг
                  Оператора.
                </li>
                <li>
                  5.5. В случае отказа Клиента от услуг Оператора, персональные
                  данные Клиента удаляются посредством уничтожения учетной
                  записи, после чего такая запись не подлежит восстановлению.
                </li>
                <li>
                  5.6. Оператор оказывает услуги Клиентам посредством размещения
                  своего интернет-сайта на сайте{" "}
                  <Link to="https://getcourse.ru/" target="_blank">
                    https://getcourse.ru/
                  </Link>
                  , представляющим собой программное обеспечение «ГЕТКУРС 2.0.»,
                  предназначенное для создания онлайн школы и организации
                  процесса обучения в сети Интернет. На сайте getcourse.ru
                  используется расширение HTTPS к протоколу HTTP в целях
                  повышения безопасности и защиты информации
                </li>
                <li>
                  5.7. Услуги хостинга и услуги предоставления выделенного
                  сервера предоставляются ООО «Селектел»{" "}
                  <Link to="https://selectel.ru/about/details" target="_blank">
                    https://selectel.ru/about/details
                  </Link>
                  .
                </li>
              </ol>

              <ol>
                <li className="li-title">
                  6. Обеспечение безопасности персональных данных
                </li>
                <li>
                  6.1. Оператор принимает необходимые организационные и
                  технические меры для защиты персональной информации Клиента от
                  неправомерного или случайного доступа, уничтожения, изменения,
                  блокирования, копирования, распространения, а также от иных
                  неправомерных действий третьих лиц. Оператор следует
                  общепринятым отраслевым стандартам для защиты личной
                  информации, предоставленной ему, на всех этапах обработки
                  персональных данных.
                </li>
                <li>
                  6.2. В соответствии с Федеральным законом Российской Федерации
                  от 27 июля 2006 г. №152- ФЗ «О персональных данных» Оператор
                  самостоятельно определяет состав и перечень мер, необходимых и
                  достаточных для обеспечения выполнения обязанностей,
                  предусмотренных законодательством в области персональных
                  данных.
                </li>
                <li>6.3. Защита персональных данных достигается путем:</li>
                <li>
                  6.3.1. Назначения ответственного за обработку и защиту
                  персональных данных;
                </li>
                <li>6.3.2. Утверждения настоящей Политики;</li>

                <li>
                  6.3.3. Организации доступа сотрудников Оператора к информации,
                  содержащей персональные данные Клиентов, в соответствии с их
                  должностными (функциональными) обязанностями;
                </li>

                <li>
                  6.4. Клиент осуществляет защиту своих персональных данных
                  путём отзыва согласия на обработку своих персональных данных
                  или другими способами, предусмотренными законодательством.
                </li>

                <li>
                  6.5. Оператор совместно с Клиентом принимает все необходимые
                  меры по предотвращению убытков или иных отрицательных
                  последствий, вызванных утратой или разглашением персональных
                  данных Клиента.
                </li>

                <li>
                  6.6. При утрате или разглашении персональных данных Оператор
                  информирует Клиента об утрате или разглашении персональных
                  данных.
                </li>
              </ol>

              <ol>
                <li className="li-title">7. Ответственность Сторон</li>
                <li>
                  7.1. Оператор в случае неисполнения своих обязательств несет
                  ответственность за убытки, понесенные Клиентом в связи с
                  неправомерным использованием персональных данных, в
                  соответствии с законодательством Российской Федерации.
                </li>
                <li>
                  7.2. В случае утраты или разглашения конфиденциальной
                  информации Оператор не несет ответственности, если данная
                  конфиденциальная информация:
                </li>
                <li>
                  7.2.1. Стала публичным достоянием до ее утраты или
                  разглашения;
                </li>
                <li>7.2.2. Была разглашена с согласия Клиента.</li>
              </ol>

              <ol>
                <li className="li-title">8. Дополнительные условия</li>
                <li>
                  8.1. Оператор вправе вносить изменения в настоящую Политику
                  обработки персональных данных без согласия Клиента.
                </li>
                <li>
                  8.2. Новая Политика вступает в силу с момента ее размещения на
                  Сайте, если иное не предусмотрено новой редакцией Политики.
                </li>
                <li>
                  8.3. Все предложения или вопросы по настоящей Политике следует
                  сообщать по email: info@devray.ru.
                </li>
                <li>
                  8.4. Действующая Политика обработки персональных данных
                  размещена на странице по адресу:{" "}
                  <Link to="/policy">https://devray.ru/policy</Link>.
                </li>
              </ol>

              <ol>
                <li className="li-title">9. Контактная информация Оператора</li>
                <li>ИП Кузьмичев Александр Александрович</li>
                <li>ИНН: 772394134132</li>
                <li>ОГРН: 323774600030800</li>
                <li>
                  E-mail: <Link to="mailto:info@devray.ru">info@devray.ru</Link>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </main>
      <div className="form__element"></div>
      <Footer />
    </div>
  );
}

export default Politics;
