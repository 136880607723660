const feedbacks = {
  data: [
    {
      id: 1,
      attributes: {
        name: "Булат Маскуров",
        post: "QA Lead в uzum bank",
        feedback:
          "Имея опыт в&nbsp;программировании на&nbsp;C++ и&nbsp;Python, но&nbsp;не&nbsp;зная Java, попал на&nbsp;курс по&nbsp;АТ к&nbsp;Саше. Примерно за&nbsp;месяц интенсивных курсов я&nbsp;довольно углубленно узнал как работает java под капотом, а&nbsp;также изучил базовый набор библиотек для автоматизации. Незадолго после курса я&nbsp;получил свой первый проект в&nbsp;качестве автоматизатора и&nbsp;по&nbsp;сей день продолжаю работать в&nbsp;тестировании.",
        image: "feedback-1.png",
      },
    },
    {
      id: 2,
      attributes: {
        name: "Виктор Витевский",
        post: "автотестировщик в альфа-банке",
        feedback:
          "Самостоятельно изучал программирование и&nbsp;волей случая попал на&nbsp;курс к&nbsp;Саше. Благодаря грамотной подаче материала и&nbsp;вовлеченности в&nbsp;сам предмет он&nbsp;помог мне систематизировать знания и&nbsp;определиться с&nbsp;дальнейшим выбором направления. Уже 2&nbsp;года работаю в&nbsp;автоматизации тестирования и&nbsp;безумно рад этому.",
        image: "feedback-2.png",
      },
    },
    {
      id: 3,
      attributes: {
        name: "ОЛЕГ АНДРИАНОВ",
        post: "Luxoft Serbia Senior QA automation engineer",
        feedback:
          "Учился у&nbsp;Саши в&nbsp;2019&nbsp;году. Курс очень понравился, Саша рассказал все нужные темы понятным языком, с&nbsp;полезными практическими заданиями, Благодаря этому я&nbsp;без опыта в&nbsp;IT смог без проблем устроиться работать специалистом по&nbsp;автоматизации тестирования, кем и&nbsp;работаю уже 4&nbsp;года.",
        image: "feedback-4.png",
      },
    },
    {
      id: 4,
      attributes: {
        name: "Дмитрий Абдуразаков",
        post: "backend-разработчик в росбанке",
        feedback:
          "Начал учиться в&nbsp;группе у&nbsp;Саши в&nbsp;школе&nbsp;АТ имея за&nbsp;спиной небольшие знания в&nbsp;java. За&nbsp;время обучения получилось структурировать имеющиеся знания и&nbsp;получить новые, а&nbsp;также применить их&nbsp;на&nbsp;практике. Ламповая обстановка на&nbsp;уроках и&nbsp;открытые дискуссии по&nbsp;темам. После обучения сразу прошел крещение огнем в&nbsp;сложном проекте. После 2&nbsp;лет работы автоматизатором перешел в&nbsp;разработку.",
        image: "feedback-3.png",
      },
    },
    {
      id: 5,
      attributes: {
        name: "Алена Данилина",
        post: "Автотестировщик в альфа-банке",
        feedback:
          "Саша был моим первым учителем в&nbsp;автотестировании, и&nbsp;за&nbsp;1.5 месяца он&nbsp;заложил в&nbsp;меня знания, которых более чем хватило, чтобы меня поместили на&nbsp;мой первый проект в&nbsp;Salesforce. Сейчас я&nbsp;в&nbsp;автотестировании 2&nbsp;года, отвечаю за&nbsp;раздел &laquo;Справки и&nbsp;выписки&raquo; в&nbsp;Альфа-Банке на&nbsp;всех платформах.",
        image: "feedback-6.png",
      },
    },
    {
      id: 6,
      attributes: {
        name: "Антон Громов",
        post: "Автотестировщик в beeline",
        feedback:
          "После обучения у&nbsp;Саши я&nbsp;успел поработать как QA&nbsp;Automation Engineer на&nbsp;проектах &laquo;Сбербанка&raquo;, &laquo;Luxoft&raquo;-а и&nbsp;&laquo;Билайна&raquo;. Тот фундамент, который он&nbsp;давал, остаётся со&nbsp;мной до&nbsp;сих пор. У&nbsp;Александра&nbsp;&mdash; замечательный педагогический подход: он&nbsp;умеет подавать информацию в&nbsp;таком виде, чтобы её&nbsp;можно было сразу&nbsp;же воспринять и&nbsp;использовать &laquo;здесь и&nbsp;сейчас&raquo;. Рекомендую как эксперта, педагога, и&nbsp;просто вежливого и&nbsp;тактичного человека.",
        image: "feedback-5.png",
      },
    },
    {
      id: 7,
      attributes: {
        name: "Николай Сафронов",
        post: "автотестировщик в нетмонет",
        feedback:
          "Я хотел бы выразить свою искреннюю благодарность за то, что Вы обучили меня автоматизации за такой короткий промежуток времени. Ваше умение передавать знания и опыт было впечатляющим, все было понятно и доступно. Огромное спасибо за Ваше время, терпение и профессионализм. Я рекомендую Сашу всем, кто ищет квалифи-цированного преподавателя и эксперта в области автоматизации.",
        image: "feedback-7.png",
      },
    },
    {
      id: 8,
      attributes: {
        name: "Юлия Тен",
        post: "автотестировщик в сбере",
        feedback:
          "Я очень довольна своим опытом обучения на курсе Александра Кузьмичева, рекомендую его всем, кто хочет стать профессионалом в области программирования на Java. У нас была отличная атмосфера для обучения, где каждый студент получал индивидуальную помощь и поддержку. Ну и кроме всего прочего Саша имеет очень крутой талант объяснить сложные вещи очень понятным языком.",
        image: "feedback-8.png",
      },
    },

    {
      id: 9,
      attributes: {
        name: "Анна Зимина",
        post: "Автотестировщик в Сбере",
        feedback:
          "Мне повезло попасть на школу автоматизации тестирования, где преподавал Саша. За 1,5 недели он помог достичь понимания и «разложить имеющиеся знания по полочкам», так как объяснял всегда «без воды» и на простых примерах. Саша придумывал для нас интересные домашние задания и давал подробную обратную связь. На данный момент я уже 2 года в автотестировании на проекте Сбербанка и ни разу не пожалела о выборе профессии. Желаю всем таких же первых учителей!",
        image: "feedback-9.png",
      },
    },

    {
      id: 10,
      attributes: {
        name: "Антон Климко",
        post: "автотестировщик",
        feedback:
          "Обучение у Александра было для меня ключевым шагом в моей карьере. Благодаря его ясному и доступному подходу, я получил прочный фундамент и практические навыки, которые использую уже 4 года в своей работе в роли QA Automation Engineer. Саша - отличный преподаватель, который умеет объяснить сложные концепции простыми и запоминающимися примерами. Я также хотел бы отметить, что Саша не только прекрасный учитель, но и замечательный человек. Он всегда был внимателен, терпелив и готов помочь в любых вопросах. Рекомендую его всем, кто ищет надежного и компетентного педагога в области автоматизации тестирования",
        image: "feedback-10.png",
      },
    },

    {
      id: 11,
      attributes: {
        name: "Кира ТУГОЛУКОВСКАя",
        post: "android-разработчик в Sberdevices",
        feedback:
          "Я хотела стать программистом и решила начать путь с автотести-рования. Так я попала в Сашину школу АТ. Не смотря на то, что у меня не было никакого опыта, обучаться было легко, Саша очень подробно все рассказывал с примерами применения в работе. После школы я прошла собес на автотестировщика и со временем поднялась до разаботчика.",
        image: "feedback-11.png",
      },
    },

    {
      id: 12,
      attributes: {
        name: "Алексей Cторожук",
        post: "лид тестирования в консультант+",
        feedback:
          "Хочу выразить огромную благодарность Саше Кузьмичеву за то, что за 1 месяц вложил в меня знания об автотестировании, которых оказалось достаточно для того, чтобы устроиться на свою первую работу в этой сфере! До встречи с ним изучал язык Java и не знал даже, что существует такое направление. Очень крутой коуч, рекомендую! Сейчас, спустя два года после начала карьеры в сфере IT, работаю в крупной компании «Консультант плюс» старшим тестировщиком ПО.",
        image: "feedback-12.png",
      },
    },

    {
      id: 13,
      attributes: {
        name: "Михаил Хушвахтов",
        post: "Автотестировщик в kfc",
        feedback:
          "Свой путь в автотестирование я начал со школы Саши. Этот курс оказался для меня невероятно полезным и информативным. Первое, что меня поразило, это умение Саши объяснять сложные вещи таким образом, что даже новички могли их понять. Кроме того, структура курса была очень хорошо продумана. После школы сразу нашел работу автоматизатором тестирования. Сейчас работаю около 2 лет в этой сфере.",
        image: "feedback-13.png",
      },
    },

    {
      id: 14,
      attributes: {
        name: "Николай Рыбаков",
        post: "автотестировщик в сбербанке",
        feedback:
          "Проходил обучение у Александра, имея в запасе некоторые знания по Java, но было сложно их структу-рировать, чтобы найти работу в АФТ. Саша помог сложить А + Б, за что я ему очень сильно благодарен. При выборе курса всегда сложно найти стоящего преподавателя, который будет совмещать в себе как качественного инженера так и отличного педагога. Александр отлично выступает в роли этих двух профессий и он всегда поможет разобраться в любом возникшем вопросе на простом примере, который будет понятен каждому!",
        image: "feedback-14.png",
      },
    },
  ],
  meta: {},
};
export default feedbacks;
