import React, { useState, useEffect, Fragment } from "react";
import ReactDOM from "react-dom";
import { useHistory, useLocation } from "react-router-dom";

import { Link } from "react-router-dom";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { iframe } from "../../iframe";
import "../../static/scss/home.scss";
import "../../static/scss/layout.scss";

function About() {
  const location = useLocation();

  useEffect(() => {
    function setIframe() {
      const form = document.querySelector(".form__element");
      if (iframe && location.pathname == "/about") {
        form.appendChild(iframe);
      }
    }

    setTimeout(setIframe, 300);
  }, [location]);

  return (
    <div className="about">
      <Header />

      <main className="about">
        <div className="container">
          <div className="about__wrapper">
            <div className="about__block">
              <h1 className="about__title">Привет, это&nbsp;Кузьмич!</h1>
              <div className="about__text">
                <p className="regular">
                  Здесь&nbsp;&mdash; школа грамоты от&nbsp;Кузьмича, где
                  мы&nbsp;окунемся в&nbsp;мир Java и автотестирования API
                  и&nbsp;UI. Я&nbsp;поделюсь с&nbsp;тобой секретами кодинга
                  и&nbsp;научу осваивать сложные темы с&nbsp;легкостью
                  и&nbsp;удовольствием.
                </p>
                <p className="regular">
                  Я разрабатываю автотесты на&nbsp;Java уже 7&nbsp;лет
                  и&nbsp;руковожу этим в Спортмастере. За&nbsp;время своей
                  карьеры я&nbsp;провел множество школ и курсов.
                </p>
              </div>
            </div>

            <div className="about__block large-mobile">
              <h2 className="about__title">
                Моя миссия&nbsp;&mdash; сделать обучение интересным
                и&nbsp;захватывающим
              </h2>
              <div className="about__text">
                <p className="regular">
                  На&nbsp;своих курсах я&nbsp;стараюсь объяснять сложные
                  концепции на&nbsp;красочных и&nbsp;доступных аналогиях,
                  подкрепляя их&nbsp;несложными зарисовками, графикой
                  и&nbsp;примерами из&nbsp;реальной жизни.
                </p>

                <p className="regular">
                  Я&nbsp;горжусь своим ремеслом, люблю технологии и&nbsp;рад
                  делиться своей страстью, чтобы в&nbsp;мире было еще больше
                  профессионалов.
                </p>
              </div>
            </div>

            <div className="about__block">
              <h2 className="about__title">
                Мне посчастливилось обучить много классных ребят,
                <br className="desktop" />
                выступать на&nbsp;конференциях и&nbsp;приютить 11&nbsp;котов{" "}
              </h2>
            </div>

            <div className="about__block about__block-large">
              <h2 className="about__title">Результат моих трудов —</h2>
              <Link
                to="/"
                className="about__text-large"
                onClick={(e) => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
              >
                шко
                <br className="mobile" />
                ла
              </Link>

              <div className="about__text-large_icons mobile">
                <img src="/images/right-arrow.svg" alt="" />
                <img src="/images/smile.svg" alt="" />
              </div>
            </div>

            <a
              href="https://t.me/NeonAether"
              target="_blank"
              className="about__questions"
            >
              <div className="about__questions-wrapper">
                <h1>Остались вопросы?</h1>

                <svg
                  width="354"
                  height="80"
                  viewBox="0 0 354 80"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.04826 38.2156C2.33891 37.7877 1.41697 38.0158 0.989048 38.7252C0.561127 39.4345 0.789268 40.3565 1.49862 40.7844L3.04826 38.2156ZM353.762 26.6867C353.865 25.8647 353.282 25.1148 352.46 25.0117L339.065 23.3316C338.243 23.2285 337.493 23.8113 337.39 24.6333C337.287 25.4553 337.87 26.2052 338.692 26.3083L350.598 27.8017L349.105 39.7084C349.002 40.5304 349.585 41.2803 350.407 41.3834C351.229 41.4865 351.979 40.9037 352.082 40.0818L353.762 26.6867ZM1.49862 40.7844C47.8262 68.732 114.892 81.63 180.697 79.4992C246.492 77.3687 311.348 60.2028 353.194 27.6844L351.353 25.3156C310.198 57.2972 246.055 74.3813 180.6 76.5008C115.155 78.62 48.7206 65.768 3.04826 38.2156L1.49862 40.7844Z"
                    fill="#2D8CFF"
                  />
                  <path
                    d="M140.281 19.9426C137.264 14.1166 130.074 7.82307 113.902 10.8781"
                    stroke="#2D8CFF"
                    strokeWidth="3"
                    strokeLinecap="round"
                  />
                  <path
                    d="M223.773 11.5C223.773 17.1542 219.618 21.5 214.773 21.5C209.928 21.5 205.773 17.1542 205.773 11.5C205.773 5.8458 209.928 1.5 214.773 1.5C219.618 1.5 223.773 5.8458 223.773 11.5Z"
                    stroke="#2D8CFF"
                    strokeWidth="3"
                  />
                </svg>

                <p className="h1">Отвечу в&nbsp;телеге</p>
              </div>
            </a>
          </div>
        </div>

        <div className="about-bg-1">
          <img
            srcSet="images/back-img-1@2x.png 2x"
            src="images/back-img-1.png"
            media="(min-width: 768px)"
            className="desktop"
            alt=""
          />

          <img
            srcSet="images/school-bg-mob@2x.png 2x"
            src="images/school-bg-mob.png"
            media="(max-width: 767.9px)"
            className="mobile"
            alt=""
          />
        </div>

        <div className="about-bg-2">
          <img
            srcSet="images/back-img-2-cut@2x.png 2x"
            src="images/back-img-2-cut.png"
            media="(min-width: 768px)"
            className="desktop"
            alt=""
          />
          <img
            srcSet="images/school-bg-mob-2@2x.png 2x"
            src="images/school-bg-mob-2.png"
            media="(max-width: 767.9px)"
            className="mobile"
            alt=""
          />
        </div>

        <div className="form__element"></div>

        {/* <picture>
            <source
              srcSet="images/1c-prosto/kassa-page-mob@2x.webp 2x"
              src="images/1c-prosto/kassa-page-mob.webp"
              type="image/webp"
              media="(max-width: 767px)"
            />
            <source
              src="images/1c-prosto/kassa-page-mob.png"
              srcSet="images/1c-prosto/kassa-page-mob@2x.png"
              alt=""
              media="(max-width: 767px)"
            />

            <source
              srcSet="images/1c-prosto/kassa-page@2x.webp 2x"
              src="images/1c-prosto/kassa-page.webp"
              type="image/webp"
              alt=""
              media="(min-width: 768px)"
            />
            <img
              srcSet="images/1c-prosto/kassa-page@2x.png 2x"
              src="images/1c-prosto/kassa-page.png"
              alt=""
            />
          </picture> */}
      </main>

      <Footer />
    </div>
  );
}

export default About;
