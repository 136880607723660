import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

const FormRegister = () => {
  const {
    register,
    formState: { errors, isValid },
  } = useForm({
    mode: "onBlur",
  });
  console.log('%csrc/components/form-register/index.js:11 errors', 'color: #007acc;', errors);
  useEffect(() => {
    const handleLoad = () => {
      let loc = document.getElementById("1173578662aacae4aaa2");
      loc.value = window.location.href;
      let ref = document.getElementById("1173578662aacae4aaa2ref");
      ref.value = document.referrer;

      let statUrl =
        "https://devray.getcourse.ru/stat/counter?ref=" +
        encodeURIComponent(document.referrer) +
        "&loc=" +
        encodeURIComponent(document.location.href);
      document.getElementById("gccounterImgContainer").innerHTML =
        "<img width=1 height=1 style='display:none' id='gccounterImg' src='" +
        statUrl +
        "'/>";
    };

    window.addEventListener("load", handleLoad);

    return () => {
      window.removeEventListener("load", handleLoad);
    };
  }, []);

  return (
    <>
      <form
        id="ltForm1954315"
        className="lt-normal-form lt-form-inner lt-form"
        action="https://devray.getcourse.ru/pl/lite/block-public/process-html?id=2019750848"
        method="post"
        data-open-new-window="0"
      >
        <div className="form__title">Запись в список ожидания</div>
        {/* <div className="form__title">Записаться в школу</div> */}
        <input type="hidden" name="formParams[setted_offer_id]" />
        <br />
        <input
          className="f-input"
          type="text"
          maxLength="60"
          placeholder="Имя"
          {...register("formParams[full_name]", {
            required: "Заполните поле 'Имя'",
            minLength: {
              value: 3,
              message: "Минимум 3 символа",
            },
          })}
        />
        <div className="form-error">
          {errors["formParams"] && errors["formParams"]["full_name"] && (
            <p>{errors["formParams"]["full_name"]["message"]}</p>
          )}
        </div>
        <br />
        <input
          className="f-input"
          type="text"
          maxLength="60"
          placeholder="Эл. почта"
          {...register("formParams[email]", {
            required: "Заполните поле 'Эл. почта'",
            pattern: {
              value:
                /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu,
              message: "Введите корректный Email",
            },
          })}
        />
        <div className="form-error">
          {errors["formParams"] && errors["formParams"]["email"] && (
            <p>{errors["formParams"]["email"]["message"]}</p>
          )}
        </div>

        <br />
        <input
          className="f-input telegram"
          type="text"
          maxLength="60"
          placeholder="Telegram"
          {...register("formParams[userCustomFields][1769283]", {
            required: "Заполните поле 'Telegram'",
            minLength: {
              value: 3,
              message: "Минимум 3 символа",
            },
          })}
        />
        <div className="form-error">
          {errors["formParams"] && errors["formParams"]["userCustomFields"] && errors["formParams"]["userCustomFields"]["1769283"] && (
            <p>{errors["formParams"]["userCustomFields"]["1769283"]["message"]}</p>
          )}
        </div>
        <br />
        {/* <input name="formParams[userCustomFields][1769283]" type="hidden" />
        <br /> */}

        <div className="global-confirm-checkbox-block confirm-rules-checkbox">
          <label>
            <input
              className="global-confirm-checkbox"
              type="checkbox"
              {...register("globalConfirmCheckbox", {
                required: "Подтвердите что ознакомлены с условием",
              })}
            />
            <span className="checkbox-text">
              Я подтверждаю, что&nbsp;ознакомлен с&nbsp;условиями{" "}
              <a href="https://devray.ru/offer" target="_blank">
                оферты
              </a>{" "}
              и&nbsp;даю согласие на&nbsp;обработку персональных данных
              на&nbsp;условиях{" "}
              <a href="https://devray.ru/policy" target="_blank">
                Политики конфиденциальности
              </a>
              .
            </span>
          </label>
        </div>

        <div className="form-error checkbox">
          {errors?.globalConfirmCheckbox && (
            <p>{errors?.globalConfirmCheckbox.message || "Error!"}</p>
          )}
        </div>

        <button
          type="submit"
          className="submit"
          id="button9758057"
          disabled={!isValid}
          onClick={() => {
            if (window["btnprs662aacae50f16"]) {
              return false;
            }

            window["btnprs662aacae50f16"] = true;
            setTimeout(() => {
              window["btnprs662aacae50f16"] = false;
            }, 6000);

            return true;
          }}
        >
          Записаться
        </button>
        <br />
        <input
          type="hidden"
          id="1173578662aacae4aaa2"
          name="__gc__internal__form__helper"
          className="__gc__internal__form__helper"
          value=""
        />
        <input
          type="hidden"
          id="1173578662aacae4aaa2ref"
          name="__gc__internal__form__helper_ref"
          className="__gc__internal__form__helper_ref"
          value=""
        />
        <input type="hidden" name="requestTime" value="1714072750" />
        <input
          type="hidden"
          name="requestSimpleSign"
          value="0bff1308c7c87621172822df85846608"
        />
        <input type="hidden" name="isHtmlWidget" value="1" />
      </form>
      <span id="gccounterImgContainer"></span>
    </>
  );
};

export default FormRegister;
