import React, { useState, useEffect, Fragment } from "react";
import ReactDOM from "react-dom";
import { useHistory, useLocation } from "react-router-dom";

// import { useHistory, useLocation } from "react-router";

import { Link } from "react-router-dom";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { iframe } from "../../iframe";

import "../../static/scss/home.scss";
import "../../static/scss/layout.scss";

function Offer() {
  const location = useLocation();


  useEffect(() => {
    function setIframe() {
      const form = document.querySelector(".form__element");
      if (iframe && location.pathname == "/offer") {
        form.appendChild(iframe);
      }
    }

    setTimeout(setIframe, 300);
  }, [location]);

  return (
    <div className="offer-page">
      <Header />

      <main className="offer">
        <div className="container">
          <div className="offer__wrapper">
            <div className="offer__hero">
              <div className="offer__hero-desktop">
                <span>
                  ИНН 772394134132 • ОГРНИП 323774600030800 • ИП Кузьмичев
                  Александр Александрович
                </span>
              </div>
              <div className="offer__hero-mobile">
                <span>ИНН 772394134132</span>
                <span>ОГРНИП 323774600030800</span>
                <span>ИП Кузьмичев Александр Александрович</span>
              </div>
            </div>
            <div className="offer__block">
              <h2>Публичная оферта</h2>

              <span>Редакция от 12.09.2023 года</span>

              <span>
                Индивидуальный предприниматель Кузьмичев Александр Александрович
                (далее – «Исполнитель»), размещая настоящую оферту, предлагает
                любому физическому лицу или индивидуальному предпринимателю
                (далее – «Заказчик»), заключить договор оказания платных
                образовательных услуг (далее – «Договор»).
              </span>
              <span>
                Исполнитель и Заказчик (совместно именуемые Стороны)
                предоставляют взаимные гарантии своей право- и дееспособности,
                необходимые для заключения и исполнения Договора.
              </span>
              <span>
                Настоящий документ является офертой согласно статье 437
                Гражданского кодекса Российской Федерации. Лицо, осуществившее
                акцепт оферты, приобретает все права и обязанности Заказчика,
                предусмотренные Договором.{" "}
              </span>

              <span>
                Акцептом оферты является осуществление Заказчиком одного из
                следующих действий до начала оказания Услуг:
              </span>

              <ul>
                <li>внесение полной оплаты стоимости Услуг;</li>
                <li>
                  внесение частичной оплаты стоимости Услуг (если такая
                  возможность предусмотрена Исполнителем).
                </li>
              </ul>

              <span>
                Осуществляя акцепт оферты, Заказчик гарантирует, что ознакомлен,
                соглашается, полностью и безоговорочно принимает все условия
                оферты в том виде, в каком они изложены ниже.
              </span>

              <span>
                Осуществляя акцепт оферты, Заказчик дает согласие на обработку
                персональных данных в том объеме, в котором это необходимо для
                исполнения Договора.
              </span>
            </div>

            <div className="offer__block offer__block-second">
              {/* <h2>
                Договор на предоставление услуг онлайн-школы (существенные
                условия)
              </h2> */}

              <ol>
                <li className="li-title">
                  1. Термины и определения. Общие положения.
                </li>
                <li>
                  1.1. В Договоре и вытекающих или связанных с ним отношениях
                  Сторон применяются следующие термины, определения и условия:
                  <ul>
                    <li>
                      1.1.1. <strong>Сайт</strong> – открытый для свободного
                      ознакомления, публично доступный принадлежащий Исполнителю
                      сайт, расположенный в сети Интернет на домене{" "}
                      <Link to="https://devray.ru/" target="_blank">
                        https://devray.ru/
                      </Link>{" "}
                      и его поддоменах.
                    </li>
                    <li>
                      1.1.2.{" "}
                      <strong>
                        Образовательная платформа (далее – «платформа»)
                      </strong>{" "}
                      — автоматизированная система проведения обучения,
                      используемая Исполнителем для оказания образовательных
                      услуг по Договору. При отсутствии в программе курса иного
                      указания, платформой являются интернет-сайты,
                      расположенные по адресу{" "}
                      <Link to="https://getcourse.ru/" target="_blank">
                        https://getcourse.ru/
                      </Link>{" "}
                      и{" "}
                      <Link to="https://telegram.org/?setln=ru" target="_blank">
                        https://telegram.org/?setln=ru
                      </Link>
                      . Исполнитель оставляет за собой право самостоятельно
                      определять платформу для организации образовательного
                      процесса.
                    </li>
                    <li>
                      1.1.3. <strong>Учебно-методический материал</strong> –
                      материал (аудиовизуальный/текстовый или графический),
                      созданный и транслируемый Исполнителем, предназначенный
                      для обучения Заказчика. Учебно-методический материал
                      является результатом интеллектуальной деятельности
                      Исполнителя и защищен от копирования, искажения или иного
                      незаконного использования в соответствии с положениями
                      Договора и действующим законодательством Российской
                      Федерации о коммерческой тайне.
                    </li>
                    <li>
                      1.1.4.{" "}
                      <strong>Образовательные услуги (далее – «Услуги»)</strong>{" "}
                      – платные услуги, оказываемые Исполнителем Заказчику по
                      авторской программе Исполнителя, направленные на
                      удовлетворение потребностей Заказчика в получении знаний,
                      развитии его умений, навыков и компетенций.
                    </li>
                    <li>
                      1.1.5. <strong>Онлайн-курс (далее – курс)</strong> –
                      формат оказания Услуг, совокупность последовательно
                      выстроенных онлайн-занятий и учебно-методических
                      материалов, связанных одной темой и нацеленных на передачу
                      информации Заказчику от Исполнителя.
                    </li>
                    <li>
                      1.1.6. <strong>Тариф (Режим)</strong> – набор услуг,
                      период оказания услуг и их стоимость, определенные
                      Исполнителем на Сайте.
                    </li>
                    <li>
                      1.1.7. <strong>Онлайн-занятие</strong> – тематический
                      урок, проводимый Исполнителем в режиме прямой трансляции,
                      направленный на предоставление Заказчику в дистанционном
                      формате Услуг.
                    </li>
                  </ul>
                </li>

                <li>
                  1.2. Все остальные термины, которые встречаются в тексте
                  Договора, толкуются Сторонами в соответствии с действующим
                  законодательством Российской Федерации и сложившимися в
                  информационно-телекоммуникационной сети «Интернет» обычными
                  правилами толкования соответствующих терминов.
                </li>
                <li>
                  1.3. В части предоставления услуг, в том числе доступа к
                  учебно-методическим материалам, получения обратной связи,
                  предоставления доступа к чату, видеозаписям уроков, проведению
                  онлайн-занятий, применяется законодательство об абонентском
                  договоре (ст. 429.4 ГК РФ). Услуги подлежат оплате по факту
                  предоставления Исполнителем, независимо от использования их
                  Заказчиком.
                </li>
                <li>
                  1.4. Заказчик подтверждает, что имеет доступ к
                  информационно-телекоммуникационной сети «Интернет», а также
                  возможность получать Услуги в режиме онлайн, в том числе имеет
                  возможность использования платформы и наличие программ,
                  необходимых для получения Услуг.
                </li>
                <li>
                  1.5. Заказчик подтверждает, что ознакомлен со всеми
                  приложениями к Договору.
                </li>
              </ol>
              <ol>
                <li className="li-title">2. Предмет договора</li>
                <li>
                  2.1 Исполнитель обязуется оказать Заказчику образовательные
                  услуги по программе курса «Онлайн-школа автотестирования на
                  Java».
                  {/* <ul>
                    <li>
                      предоставление услуги доступа к Онлайн-курсам с целью
                      получения информации Учениками;
                    </li>
                    <li>предоставление услуги доступа к Вебинарам;</li>
                    <li>
                      предоставление услуги проверки Ответа Ученика с целью
                      проверки правильности выполнения задания Учеником. Услуга
                      проверки Ответа Ученика предоставляется в случае, если она
                      будет предусмотрена соответствующим Онлайн-курсом и
                      Условиями прохождения Онлайн-курса.
                    </li>
                  </ul> */}
                </li>
                <li>
                  2.2 Образовательная программа относится к программе
                  дополнительного образования и не является программой
                  дополнительного профессионального образования
                  (профессиональной переподготовки или повышения квалификации).
                </li>
              </ol>
              <ol>
                <li className="li-title">3. Сроки оказания услуг</li>
                <li>
                  3.1. Сроки оказания Услуг по Договору указаны на Сайте.
                  {/* <ul>
                    <li>
                      доступ к Онлайн-курсу предоставляется в Аккаунте Ученика;
                    </li>
                    <li>
                      доступ к Онлайн-курсу может быть ограничен периодом,
                      определяемым Условиями прохождения Онлайн-курса;
                    </li>
                    <li>
                      администратор сервиса вправе изменить период доступа к
                      Онлайн-курсу;
                    </li>
                    <li>
                      онлайн-курс может содержать один и более Стоп-уроков.
                      Количество Стоп-уроков устанавливается Администратором
                      Сервиса;
                    </li>
                    <li>
                      в случае если Ученик не выполнил требования Стоп-урока или
                      иные требования, заложенные в Условиях прохождения
                      Онлайн-курса, доступ к последующим Урокам Онлайн-курса
                      Ученику не предоставляется до момента выполнения
                      необходимых требований, но в рамках периода доступа к
                      Онлайн-курсу и в соответствии с Условиями прохождения
                      онлайн-курса.
                    </li>
                  </ul> */}
                </li>
                <li>
                  3.2. В случае возникновения обстоятельств, препятствующих
                  проведению занятия согласно установленному расписанию,
                  Исполнитель вправе перенести проведение занятия как
                  непосредственно перед началом занятия, так и во время занятия.
                  К обстоятельствам, препятствующим проведению занятий,
                  относятся: болезнь Исполнителя, несчастный случай,
                  непредвиденный выход из строя оборудования, необходимого для
                  проведения занятий, непреодолимые проблемы с
                  Интернет-соединением и прочие подобные обстоятельства, не
                  зависящие от воли Исполнителя. При этом сроки оказания Услуг
                  продлеваются на срок занятий, которые не были проведены
                  Исполнителем, в соответствии с установленным расписанием и
                  программой.
                </li>
                <li>
                  3.3. Услуга предоставления ответов на вопросы в чате - 12
                  недель. При наличии возможности Исполнитель сохраняет доступ к
                  чату после окончания данного срока.
                  {/* <ul>
                    <li>
                      Вебинары проводятся в прямой трансляции или в записи, в т.
                      ч. с имитацией действий Преподавателя и других зрителей.
                      Порядок получения доступа к Вебинару определяется
                      Администратором Сервиса.
                    </li>
                    <li>
                      По факту завершения Вебинара Ученикам может быть
                      предоставлена запись Вебинара. Порядок предоставления
                      доступа к записи Вебинара определяется Администратором
                      Сервиса.
                    </li>
                    <li>
                      При наличии технической возможности, Администратор Сервиса
                      предоставляет Ученику специальную форму общения (чата) с
                      Преподавателем во время Вебинара.
                    </li>
                  </ul> */}
                </li>
                <li>
                  3.4. Исполнитель безвозмездно сохраняет доступ к материалам
                  курса на платформе 180 дней с момента начала курса.
                </li>
                <li>
                  3.5. Заказчик не вправе в одностороннем порядке изменить время
                  прохождения обучения и(или) требовать оказания Услуг в иные,
                  нежели установленные Исполнителем сроки.
                </li>
              </ol>
              <ol>
                <li className="li-title">4. Порядок оказания услуг</li>
                <li>
                  4.1. Услуги оказываются Исполнителем дистанционно – при помощи
                  информационно-телекоммуникационной сети «Интернет».
                </li>
                <li>
                  4.2. После оплаты Услуг Заказчиком Исполнитель предоставляет
                  Заказчику доступ к платформе, действительный на весь период
                  срока оказания Услуг. В случае если в установленные сроки
                  доступ к платформе Исполнителем не предоставлен, Заказчик
                  обязуется связаться с Исполнителем по адресу электронной
                  почты, указанной в реквизитах Договора, предоставив копию
                  документа об оплате.
                </li>
                <li>
                  4.3. В случае, если Заказчик не совершил действия, указанные в
                  п. 4.2. Договора, при этом срок оказания Услуг наступил,
                  Услуги считаются оказанными Исполнителем.
                </li>
                <li>
                  4.4. Услуги Исполнителя состоят из отдельных, оказываемых и
                  принимаемых независимо друг от друга услуг, и переход к
                  получению следующей услуги говорит о приемке прошлой.
                </li>
                <li>
                  4.5. Услуга в части проведения онлайн-занятий оказывается и
                  принимается по каждому онлайн-занятию отдельно. Услуга
                  считается оказанной в момент окончания каждого онлайн-занятия,
                  вне зависимости от того, присутствовал ли Заказчик на
                  онлайн-занятии.
                </li>
                <li>
                  4.5. Частичная оплата счета и условия предоставления услуг при
                  частичной оплате допускается по решению Администратора
                  Сервиса.
                </li>

                <li>
                  4.6. Услуги в части предоставления доступа к видеозаписи
                  онлайн-занятия и к учебно-методическим материалам считаются
                  оказанными в момент размещения соответствующих материалов на
                  Платформе.
                </li>
                <li>
                  4.7. Услуги в части предоставления доступа к чату считаются
                  оказанными в момент предоставления соответствующего доступа
                  (путем направления ссылки для входа в чат или добавления в
                  чат), вне зависимости от того, воспользовался ли Заказчик
                  доступом, и когда именно он им воспользовался.
                </li>
                <li>
                  4.8. Срок ответа на вопросы в чате на тарифе «Новичок» - 1
                  рабочий день.
                </li>
                <li>
                  4.9. Заказчик, выбравший тариф «Новичок», выполняет домашнее
                  задание и представляет его на проверку Исполнителю в срок не
                  позднее 3 недель с момента проведения онлайн-занятия, на
                  котором оно было задано. Услуга по проверке домашнего задания
                  предоставляется по требованию Заказчика. В случае если
                  Заказчик в установленный срок не предъявил требования по
                  проверке домашнего задания (не выполнил и не выслал домашнее
                  задание на проверку Исполнителю), услуга по проверке домашнего
                  задания считается оказанной в полном объеме, так как данные
                  обстоятельства не зависят от воли Исполнителя. Срок проверки –
                  до 5 дней.
                </li>
                <li>
                  4.10. К защите дипломного работы на индивидуальном созвоне с
                  Исполнителем допускается Заказчик, представивший и успешной
                  прошедший проверку дипломной работы (задания, данного
                  Исполнителем).
                </li>
                <li>
                  4.11. Электронный сертификат об успешном окончании обучения в
                  качестве студента выдается Заказчику, выбравшему тариф
                  «Новичок», успешно выполнившему домашние задания к 14 урокам
                  из 16 и успешно защитившему дипломный проект на индивидуальном
                  созвоне с Исполнителем.
                </li>
                <li>
                  4.12. Электронный сертификат о прохождении курса в качестве
                  слушателя выдается Заказчику, прошедшему полностью обучение по
                  тарифу «Выживач» или прошедшему полностью обучение по тарифу
                  «Новичок», но не получившему сертификат об успешном окончании
                  обучения в качестве студента.
                </li>
                <li>
                  4.13. В состав услуг, предоставляемых Заказчику, могут быть
                  включены бонусные (бесплатные) материалы других спикеров, не
                  входящие в образовательную программу Исполнителя.
                </li>
                <li>
                  4.14. Стороны пришли к соглашению об отсутствии необходимости
                  подписания акта приема-передачи оказанных услуг. Отсутствие
                  претензий со стороны Заказчика, направленных в письменной
                  форме в адрес Исполнителя в течение двух календарных дней с
                  даты завершения оказания конкретной услуги, считается
                  подтверждением факта полной и безоговорочной приемки по
                  качеству и объему услуг.
                </li>
                <li>
                  4.15. В случае, если Заказчик не обратится за предоставлением
                  какой-либо услуги в сроки, предусмотренные для оказания каждой
                  услуги, Исполнитель не несет ответственности за неоказание
                  (ненадлежащее оказание) услуг по настоящему договору и возврат
                  стоимости услуг не осуществляет.
                </li>
              </ol>
              <ol>
                <li className="li-title">5. Права и обязанности Сторон</li>
                <li>
                  5.1. <strong>Заказчик вправе:</strong>
                </li>
                <li>
                  5.1.1. Получать выбранные и оплаченные Услуги в соответствии с
                  условиями Договора.
                </li>
                <li>
                  5.1.2. Самостоятельно принимать решения относительно
                  необходимости совершения тех или иных действий, рекомендуемых
                  Исполнителем в рамках оказания Услуг по Договору.
                </li>
                <li>
                  5.1.3. Реализовывать иные права, предусмотренные Договором и
                  законодательством Российской Федерации.
                </li>
                <li>
                  5.2. <strong>Заказчик не вправе:</strong>
                </li>
                <li>
                  5.2.1. Обходить технические ограничения, установленные на
                  платформе.
                </li>
                <li>
                  5.2.2. Нарушать требования раздела Договора об
                  интеллектуальной собственности и конфиденциальности.
                </li>
                <li>
                  5.2.3. Создавать продукты, производные и аналогичные курсу
                  Исполнителя, распространять, передавать третьим лицам или иным
                  образом использовать частично или полностью
                  учебно-методические материалы и содержание обучения
                  Исполнителя.
                </li>
                <li>
                  5.2.4. Продавать, уступать право требования к Исполнителю.
                </li>
                <li>
                  5.3. <strong>Заказчик обязан:</strong>
                </li>
                <li>
                  5.3.1. Своевременно и самостоятельно изучать
                  учебно-методические материалы, знакомиться с информацией о
                  проводимых онлайн-занятиях, принимать участие в организованных
                  Исполнителем онлайн-занятиях, своевременно выполнять
                  необходимые задания.
                </li>
                <li>
                  5.3.2. Неукоснительно и безоговорочно соблюдать правила
                  поведения при получении Услуг Исполнителя, установленные
                  Договором и приложениями к Договору.
                </li>
                <li>
                  5.3.3. Обратиться к Исполнителю по электронной почте в случае
                  наличия вопросов по порядку оказания услуг. Отсутствие
                  обращений Заказчика свидетельствует о том, что Заказчик
                  ознакомлен с необходимой и достаточной для него информацией об
                  оказываемых по Договору Услугах.
                </li>
                <li>
                  5.3.4. Для получения Услуг самостоятельно настроить
                  программное обеспечение, аппаратную часть и Интернет-канал
                  своего персонального компьютера и мобильного устройства таким
                  образом, чтобы иметь возможность беспрепятственно пользоваться
                  всеми сервисами{" "}
                  <Link to="https://getcourse.ru/" target="_blank">
                    https://getcourse.ru/
                  </Link>
                  ,{" "}
                  <Link to="https://telegram.org/?setln=ru/" target="_blank">
                    https://telegram.org/?setln=ru/
                  </Link>
                  ,{" "}
                  <Link to="https://zoom.us/" target="_blank">
                    https://zoom.us/
                  </Link>{" "}
                  и другими сервисами, которые используются в ходе оказания
                  Услуг.
                </li>
                <li>
                  5.3.5. С момента оплаты Услуг Исполнителя ежедневно проверять
                  указанную при регистрации на платформе электронную почту, в
                  том числе папку «Спам» на предмет получения сообщений от
                  Исполнителя. В случае, когда направленное Исполнителем
                  сообщение попадает в папку «Спам», оно считается полученным
                  Заказчиком независимо от того, прочел ли его Заказчик.
                </li>
                <li>
                  5.3.6. Предпринять все зависящие от него действия, необходимые
                  для прохождения и успешного завершения обучения.
                </li>
                <li>
                  5.4. <strong>Исполнитель вправе:</strong>
                </li>
                <li>
                  5.4.1. Не приступать к оказанию Услуг либо приостановить
                  оказание Услуг и доступ к личному кабинету на платформе
                  Заказчика до устранения соответствующего нарушения, при
                  наличии любого из следующих оснований: предоставление
                  Заказчиком недостоверной информации при регистрации на
                  платформе.
                </li>
                <li>
                  5.5. <strong>Исполнитель обязан:</strong>
                </li>
                <li>
                  5.5.1. Оказать оплаченные Заказчиком Услуги надлежащего
                  качества и объёма в установленные сроки.
                </li>
                <li>
                  5.5.2. Не разглашать информацию о персональных данных
                  Заказчика без его согласия и осуществлять их обработку
                  исключительно в соответствии с Политикой в отношении обработки
                  персональных данных, утвержденной Исполнителем.
                </li>
                <li>
                  5.5.3. Удалить Заказчика из группового чата, прекратить доступ
                  к онлайн-занятию в случае нарушения Заказчиком Правил
                  поведения и коммуникации, указанных в Приложении №2 к
                  Договору, без возмещения Заказчику убытков. Эта обязанность
                  обусловлена тем, что Заказчик, нарушая установленные
                  Исполнителем в Договоре и приложениях к нему правила поведения
                  при получении Услуг, совершает действия, которые исключают
                  возможность оказания надлежащих и качественных Услуг как
                  самому Заказчику, так и другим Заказчикам. По своей природе
                  такая обязанность Исполнителя является вынужденной действиями
                  Заказчика мерой, направленной на защиту прав и интересов не
                  только Исполнителя, но и других Заказчиков.
                </li>
              </ol>
              <ol>
                <li className="li-title">
                  6. <strong>Стоимость услуг и порядок их оплаты.</strong>
                </li>
                <li>
                  6.1. Стоимость тарифов указана на Сайте Исполнителя. При этом
                  стороны признают, что цена Услуг, входящих в Тариф,
                  составляет:
                  <ul>
                    <li>
                      6.1.1. Стоимость 16 онлайн-занятий равна стоимости тарифа
                      «Выживач». Цена каждого онлайн-занятия признается равной.
                    </li>
                    <li>
                      6.1.2. Стоимость проверки домашних заданий на тарифе
                      «Новичок» равна разности цены тарифов «Новичок» и
                      «Выживач». При этом стоимость проверки домашнего задания к
                      каждому онлайн-занятию признается равной.
                    </li>
                    <li>
                      6.1.3. Остальные Услуги, входящие в стоимость тарифов,
                      являются бесплатными.
                    </li>
                  </ul>
                </li>
                <li>
                  6.2. Стоимость Услуг определяется Исполнителем в одностороннем
                  порядке, валюта Договора – рубль Российской Федерации.
                </li>
                <li>
                  6.3. Моментом оплаты считается дата поступления денежных
                  средств на расчетный счет Исполнителя. Заказчик обязуется
                  хранить все документы, подтверждающие осуществление оплаты, до
                  момента зачисления денежных средств на расчетный счет
                  Исполнителя. Если денежные средства от Заказчика не поступили
                  на расчетный счет Исполнителя в установленный срок и (или) в
                  установленном размере, последний имеет право не приступать к
                  оказанию Услуг либо прекратить их оказание.
                </li>
                <li>
                  6.4. Исполнитель вправе устанавливать различные скидки от
                  стоимости оказываемых Услуг и специальные предложения,
                  применяемые в том числе по промокоду, которые действуют
                  ограниченное время. В таком случае Заказчик соглашается с
                  условиями специальных предложений, выражая свой акцепт путём
                  оплаты Услуг по специальной цене (со скидкой).
                </li>
                <li>
                  6.5. Заказчик вправе воспользоваться рассрочкой/кредитом,
                  предоставляемыми сервисами рассрочки платежей или банками. В
                  данном случае Исполнитель не вступает в финансово-кредитные
                  отношения с Заказчиком, и не выступает в качестве кредитора
                  Заказчика. Заказчик самостоятельно исполняет свои кредитные
                  обязательства перед выбранным им банком/сервисом.
                </li>
              </ol>
              <ol>
                <li className="li-title">
                  7. <strong>Порядок возврата денежных средств</strong>
                </li>
                <li>
                  7.1. Прием к рассмотрению претензий Заказчика с требованием о
                  возврате денежных средств прекращается последним днем оказания
                  Услуг, если не указано другое, что безоговорочно принимается
                  Сторонами.
                </li>
                <li>
                  7.2. Заказчик вправе отказаться от заказанных Услуг
                  Исполнителя в любое время до начала оказания Исполнителем
                  Услуг.
                </li>
                <li>
                  7.3. В случае отказа Заказчика от Услуг Исполнителя до начала
                  оказания Услуг, уплаченная Заказчиком сумма за Услугу подлежит
                  возврату Заказчику за вычетом понесенных Исполнителем
                  фактических расходов и комиссий банков/платежных систем и иных
                  сервисов.
                </li>
                <li>
                  7.4. В случае отказа Заказчика от Услуг Исполнителя после
                  частичного оказания Услуг уплаченная Заказчиком сумма за
                  Услуги подлежит возврату Заказчику за вычетом стоимости
                  оказанных Услуг и расходов, понесенных Исполнителем до момента
                  получения заявления от Заказчика о расторжении. К расходам
                  Исполнителя относятся в том числе банковские комиссии,
                  комиссии платежных посредников и иных сервисов.
                </li>
                <li>
                  7.5. В случае отказа Заказчика от Услуг Исполнителя,
                  приобретенных по специальной цене, расчет стоимости оказанных
                  услуг производится на основании п.6.1. Договора от полной цены
                  тарифа, указанной на Сайте.
                </li>
                <li>
                  7.6. Заявление о возврате денежных средств, а также претензии
                  по качеству оказываемых Исполнителем услуг должны направляться
                  Заказчиком на электронную почту Исполнителя. Срок рассмотрения
                  претензии Заказчика Исполнителем составляет 10 (десять)
                  календарных дней с момента поступления претензии в адрес
                  Исполнителя.
                </li>
                <li>
                  7.7. Заявление о возврате денежных средств должно содержать:
                  <ul>
                    <li>
                      данные Заказчика (ФИО, адрес проживания, телефон, адрес
                      электронной почты);
                    </li>
                    <li>
                      сумма оплаченных Услуг и информация об оплате (дата, номер
                      заказа, сумма заказа, способ оплаты, электронный адрес, на
                      который был оформлен заказ продукта);
                    </li>
                    <li>причины возврата;</li>
                    <li>реквизиты для перевода денежных средств;</li>
                    <li>дата, подпись и расшифровка;</li>
                    <li>приложения (документ об оплате, иные документы).</li>
                  </ul>
                </li>
                <li>
                  7.8. Возврат осуществляется в течение 10 (десяти) календарных
                  дней с момента предъявления соответствующего требования.
                </li>
                <li>
                  7.9. Финансовый документ, подтверждающий внесение денежных
                  средств Исполнителем на счет Заказчика, является
                  доказательством исполнения Исполнителем обязанности по
                  возврату денежных средств Заказчику, что безоговорочно
                  принимается Сторонами.
                </li>
                <li>
                  7.10. В случае досрочного расторжения договора Заказчиком
                  доступ к материалам курса прекращается.
                </li>
                <li>
                  7.11. Заказчик подтверждает, что его несогласие с мнением
                  Исполнителя и содержанием Услуг не является основанием для
                  возврата денежных средств и предъявления претензий по качеству
                  Услуг.
                </li>
                <li>
                  7.12. Исполнитель не несет ответственности за несоответствие
                  предоставленной услуги ожиданиям Заказчика и/или за его
                  субъективную оценку. Такое несоответствие ожиданиям и/или
                  отрицательная субъективная оценка не являются основаниями
                  считать услуги оказанными некачественно или не в согласованном
                  объеме.
                </li>
                <li>
                  7.13. Исполнитель не несет ответственности за невозможность
                  предоставления услуг Заказчику по причинам, связанным с
                  нарушением работы Интернет-канала, оборудования или
                  программного обеспечения со стороны Заказчика, а также по
                  любым другим причинам, препятствующим получению Заказчиком
                  услуг, возникшим не по вине Исполнителя.
                </li>
                <li>
                  7.14. Исполнитель не гарантирует достижение Заказчиком
                  желаемого результата, так как это зависит от продолжительности
                  и регулярности занятий Заказчика, его индивидуальных
                  особенностей и личных качеств, качества и скорости
                  освоения/внедрения получаемых от Исполнителя знаний и
                  материалов.
                </li>
                <li>
                  7.15. Для прохождения курса Заказчику понадобятся следующее
                  программное обеспечение: Java (JDK) -{" "}
                  <Link to="https://jdk.java.net/java-se-ri/17" target="_blank">
                    https://jdk.java.net/java-se-ri/17
                  </Link>
                  , Git Bash -{" "}
                  <Link to="https://git-scm.com/downloads" target="_blank">
                    https://git-scm.com/downloads
                  </Link>
                  , Apache Maven -{" "}
                  <Link
                    to="https://maven.apache.org/download.cgi"
                    target="_blank"
                  >
                    https://maven.apache.org/download.cgi
                  </Link>
                  , Intellij IDEA Community Edition -{" "}
                  <Link to="https://www.jetbrains.com/idea/" target="_blank">
                    https://www.jetbrains.com/idea/
                  </Link>
                  download/ ?section=windows, Docker Desktop -{" "}
                  <Link
                    to="https://www.docker.com/products/docker-desktop"
                    target="_blank"
                  >
                    https://www.docker.com/products/docker-desktop
                  </Link>
                  , а также иное ПО, о котором Исполнитель расскажет в ходе
                  обучения.
                </li>
              </ol>
              <ol>
                <li className="li-title">
                  8. Конфиденциальность. Интеллектуальная собственность
                </li>
                <li>
                  8.1. Стороны признают, что материалы курса, доступ к которым
                  получает Заказчик, являются результатом интеллектуальной
                  деятельности Исполнителя, а также содержат конфиденциальную
                  информацию, запрещенную к распространению.
                </li>
                <li>
                  8.2. Заказчик обязуется не совершать действий, направленных на
                  распространение или раскрытие информации, или создающих угрозу
                  ее раскрытия или распространения, в частности:
                  <ul>
                    <li>
                      8.2.1. предоставление третьим лицам логина и пароля от
                      личного кабинета на Платформе;
                    </li>
                    <li>
                      8.2.2. предоставление третьим лицам доступа (ссылки,
                      приглашения) в закрытый чат;
                    </li>
                    <li>
                      8.2.3. копирование учебно-методических материалов любым
                      способом, передача распечатанных материалов на бумажном
                      носителе, пересылка материалов третьим лицам на
                      электронную почту или в мессенджеры,
                    </li>
                    <li>
                      8.2.4. разглашение любым способом информации, полученной
                      от Исполнителя или других Заказчиков, через групповые чаты
                      в мессенджерах.
                    </li>
                  </ul>
                </li>

                <li>
                  8.3. Заказчик не имеет права копировать, транслировать,
                  рассылать, публиковать в исходном, а также измененном виде
                  любые материалы курса, а также иным образом нарушать авторские
                  права Исполнителя или других лиц.
                </li>
                <li>
                  8.4. Заказчик не имеет права нарушать авторские права и
                  использовать результаты интеллектуальной деятельности других
                  Заказчиков.
                </li>
                <li>
                  8.5. Действия и/или бездействие Заказчика, повлекшие нарушение
                  прав Исполнителя или направленные на нарушение прав
                  Исполнителя на любые материалы, сайт, курс или их компоненты,
                  влекут уголовную, гражданскую и административную
                  ответственность в соответствии с законодательством Российской
                  Федерации.
                </li>
                <li>
                  8.6. За каждый случай нарушения исключительных прав
                  Исполнителя или условий о конфиденциальности Заказчик обязан
                  заплатить Исполнителю компенсацию в размере 500 000 (пятьсот
                  тысяч) рублей.
                </li>
                <li>
                  8.7. Требование Исполнителя о выплате компенсации подлежит
                  немедленному удовлетворению в досудебном порядке. В случае
                  отказа либо неудовлетворения Заказчиком требования о выплате
                  штрафа в течение 5 (пяти) календарных дней с момента
                  направления Исполнителем соответствующего требования,
                  Исполнитель вправе обратиться в суд за защитой нарушенного
                  права с возложением на Заказчика судебных издержек.
                </li>
              </ol>
              <ol>
                <li className="li-title">9. Обработка персональных данных</li>
                <li>
                  9.1. Персональные данные Заказчика обрабатываются в
                  соответствии с Федеральным Законом «О персональных данных» №
                  152-ФЗ. При регистрации на Сайте Заказчик предоставляет
                  следующую информацию: имя, адрес электронной почты, Telegram
                  (имя пользователя или телефон). Для получения сертификата
                  Заказчик указывает фамилию, имя и отчество.
                </li>
                <li>
                  9.2. Предоставляя свои персональные данные Исполнителю,
                  Заказчик соглашается на их обработку Исполнителем в целях
                  выполнения Исполнителем обязательств перед Заказчиком в рамках
                  настоящего Договора.
                </li>
                <li>
                  9.3. Под обработкой персональных данных понимается любое
                  действие (операция) или совокупность действий (операций),
                  совершаемых Исполнителем с использованием средств
                  автоматизации или без использования таких средств с
                  персональными данными, включая сбор, запись, систематизацию,
                  накопление, хранение, уточнение (обновление, изменение)
                  извлечение, использование, обезличивание, блокирование,
                  удаление, уничтожение персональных данных. 
                </li>
                <li>
                  9.4. Оставляя Исполнителю отзывы (в любой форме), Заказчик
                  предоставляет Исполнителю согласие на обнародование и
                  дальнейшее использование текстового содержания отзывов/кейсов,
                  изображения Заказчика (в том числе, аватара в социальной сети)
                  в фото-, видеоматериалах, равно как и зафиксированного в
                  независимых друг от друга кадрах таких видеоматериалов, а
                  также зафиксированного в любых иных объектах изображения в
                  целях размещения такого изображения на сайтах Исполнителя, в
                  информационных и рекламных материалах Исполнителя и любых иных
                  целях, связанных с деятельностью Исполнителя и не
                  противоречащих действующему законодательству. Согласие
                  действует с даты заключения Договора. Заказчик подтверждает,
                  что, давая такое согласие, действует по собственной воле и в
                  своих интересах.
                </li>
                <li>
                  9.5. Заказчик имеет право на предоставление отзыва/кейса
                  Исполнителю без указания имени (анонимно). Заказчик имеет
                  право в любое время отозвать согласие на размещение
                  отзыва/кейса/изображения Заказчика, направив Исполнителю
                  соответствующее уведомление на адрес электронной почты.
                </li>
                <li>
                  9.6. Исполнитель не несет ответственности за сведения,
                  предоставленные Заказчиком на Сайте в общедоступной форме. 
                </li>
                <li>
                  9.7. Исполнитель вправе осуществлять записи телефонных
                  разговоров, видеозапись занятий и встреч с Заказчиком.
                  Заказчик дает согласие на размещение видеозаписей,
                  произведенных в ходе получения Услуг по настоящему Договору,
                  другим заказчикам.
                </li>
              </ol>
              <ol>
                <li className="li-title">
                  10. Изменение или расторжение договора. Ответственность
                  сторон.
                </li>
                <li>
                  10.1. Исполнитель вправе расторгнуть Договор в одностороннем
                  порядке в случаях:
                  <ul>
                    <li>
                      10.1.1. неоднократного (2 и более раз) нарушения
                      обязанностей, установленных в Приложении №2 Договора. В
                      этом случае Заказчику возвращаются денежные средства
                      только в части Услуг, которые не оказаны Исполнителем
                      Заказчику на момент прекращения Заказчику доступа к
                      платформе;
                    </li>
                    <li>
                      10.1.2. нарушение Заказчиком сроков и иных условий оплаты
                      Услуг по Договору;
                    </li>
                    <li>
                      10.1.3. нарушения Заказчиком условий раздела Договора о
                      конфиденциальности и интеллектуальной собственности. В
                      этом случае внесенная плата за Услуги Заказчику не
                      возвращается и засчитывается в счет компенсации нарушенных
                      прав Исполнителя.
                    </li>
                    <li>
                      10.1.4. нарушения Заказчиком пункта 10.5 Договора. В этом
                      случае внесенная плата за Услуги Заказчику не возвращается
                      и засчитывается в счет компенсации нарушенных прав
                      Исполнителя.
                    </li>
                  </ul>
                </li>

                <li>
                  10.2. Исполняя пункт 10.1 Договора, Исполнитель не обязан
                  компенсировать Заказчику убытки, поскольку одностороннее
                  расторжение Договора Исполнителем является вынужденной мерой
                  на нарушение Заказчиком условий Договора.
                </li>
                <li>
                  10.3. Заказчик не вправе изменять условия Договора в
                  одностороннем порядке, а равно требовать от Исполнителя
                  изменения условий Договора.
                </li>
                <li>
                  10.4. Заказчик обязан не допускать распространение любыми
                  способами недостоверной информации об Услугах Исполнителя, а
                  также информацию, порочащую деловую репутацию Исполнителя. За
                  неисполнения настоящего пункта Заказчик обязан прекратить
                  нарушение и возместить Исполнителю компенсацию в размере 100
                  000 (сто тысяч) рублей за каждый установленный случай.
                </li>
                <li>
                  10.5. В случае нарушения иных условий Договора, стороны несут
                  ответственность согласно действующему законодательству
                  Российской Федерации.
                </li>
              </ol>
              <ol>
                <li className="li-title">11. Заключительные положения.</li>
                <li>
                  11.1. По всем вопросам, не урегулированным Договором, Стороны
                  руководствуются законодательством Российской Федерации.
                </li>
                <li>
                  11.2. Признание судом какого-либо положения Договора
                  недействительным или не подлежащим принудительному исполнению
                  не влечет недействительности иных положений Договора.
                </li>
                <li>
                  11.3. Договор действует на территории всех стран мира и в
                  информационно-телекоммуникационной сети «Интернет».
                </li>
              </ol>

              <span>
                <strong>Реквизиты Исполнителя:</strong>
                <br />
                Индивидуальный предприниматель Кузьмичев Александр Александрович
                <br />
                ОГРНИП: 323774600030800,
                <br />
                ИНН: 772394134132
                <br />
                г. Москва
                <br />
                Электронная почта{" "}
                <Link to="mailto:info@devray.ru" target="_blank">
                  info@devray.ru
                </Link>
              </span>
            </div>

            <div className="offer__block offer__block-third">
              <span>
                Приложение № 1 к публичной оферте (договор оказания платных
                образовательных услуг)
              </span>
              <p>
                Услуги, входящие в тарифы программы «Онлайн-школа
                автотестирования на Java».
              </p>

              <table id="tariffs">
                <thead>
                  <tr>
                    <th>Услуга</th>
                    <th>Выживач</th>
                    <th>Новичок</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Онлайн-занятие в Zoom</td>
                    <td>+</td>
                    <td>+</td>
                  </tr>
                  <tr>
                    <td>Доступ к чату в Telegram</td>
                    <td>+</td>
                    <td>+</td>
                  </tr>
                  <tr>
                    <td>Проверка домашнего задания</td>
                    <td>-</td>
                    <td>+</td>
                  </tr>
                  <tr>
                    <td>Ответы на вопросы в чате* </td>
                    <td>*Не гарантируется (по занятости преподавателя)</td>
                    <td>+</td>
                  </tr>
                  <tr>
                    <td>
                      Курирование и помощь в процессе выполнения дипломной
                      работы
                    </td>
                    <td>-</td>
                    <td>+</td>
                  </tr>
                  <tr>
                    <td>Сертификат о прохождении курса в качестве слушателя</td>
                    <td>+</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>Сертификат об окончании курса в качестве студента</td>
                    <td>-</td>
                    <td>+</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="offer__block offer__block-four">
              <span>
                Приложение № 2 к публичной оферте (договор оказания платных
                образовательных услуг)
              </span>

              <span>
                Правила поведения и коммуникации при получении Услуг Исполнителя
                по Договору
              </span>

              <span>При получении услуг Заказчик обязан:</span>

              <ol>
                <li>
                  1. соблюдать дисциплину и общепринятые нормы поведения, в
                  частности, проявлять уважение к представителям Исполнителя,
                  Исполнителю и другим Заказчикам, не посягать на их честь и
                  достоинство;
                </li>
                <li>
                  2. не допускать агрессивного поведения, не мешать
                  представителю Исполнителя или другим Заказчикам при оказании
                  /получении услуг;
                </li>
                <li>
                  3. не использовать информацию, полученную от Исполнителя,
                  способами, которые могут привести или приведут к нанесению
                  ущерба интересам Исполнителя;
                </li>
                <li>
                  4. не появляться на онлайн-занятиях с признаками/в состоянии
                  алкогольного, наркотического или иного опьянения;
                </li>
                <li>
                  5. не употреблять в общении (в чатах, на платформе) выражения,
                  которые нарушают честь, достоинство и деловую репутацию
                  Исполнителя, а также честь и достоинство других Заказчиков;
                </li>
                <li>
                  6. не распространять рекламу и не предлагать услуги сторонних
                  ресурсов, свои услуги или услуги третьих лиц среди других
                  Заказчиков и лиц, привлеченным Исполнителя\ем для оказания
                  услуг, в том числе в групповых чата Исполнителя в
                  мессенджерах. Вместе с тем допускается реклама своих
                  услуг/своего продукта.
                  <br />
                  Заказчиком в рамках выполнения домашнего задания, когда данное
                  действие непосредственно предусмотрено домашним заданием;
                </li>
                <li>7. не размещать информацию негативного характера;</li>
                <li>
                  8. не провоцировать других Заказчиков на отказ от услуг
                  Исполнителя.
                </li>
              </ol>

              <p>
                Данный перечень не является исчерпывающим. Исполнитель вправе
                устанавливать и размещать на платформе и (или) в чате
                дополнительные правила поведения и коммуникации, которые обязан
                соблюдать Заказчик.
              </p>

              <p>
                В случае однократного нарушения Заказчиком правил поведения и
                коммуникации при получении услуг Исполнителя, Заказчик получает
                предупреждение от Исполнителя о недопустимости нарушения условий
                Договора. При повторном нарушении правил Заказчик подлежит
                удалению из чата.
              </p>
            </div>
          </div>
        </div>
      </main>
      <div className="form__element"></div>
      <Footer />
    </div>
  );
}

export default Offer;
