/**
 * Селектор по умолчанию для тегов со временем
 */
const DEFAULT_SELECTOR: string = 'a[data-anchor=true]';

/**
 * Дополнительная граница от верха страницы
 */
const PAGE_TOP: number = 72;


export default function initAnchorLinks(document: Document, window: Window, selector?: string): void {
	selector = selector || DEFAULT_SELECTOR;
	document.querySelectorAll(selector).forEach((element: Element) => {
		processElement(element, document, window);
	});

	setTimeout(() => {
		if (window.location.hash) {
			const target: HTMLElement = document.querySelector(window.location.hash);
			if (!target) {
				return;
			}
			scrollTo(target);
		}
	}, 1)
}

export function processElement(element: Element, document: Document, window: Window): void {
	let href = element.getAttribute('data-anchor') || '';
	if (!href.match(/^#/)) {
		href = element.getAttribute('href');
	}
	if (!href.match(/^#/)) {
		return;
	}
	if (href.match(/^##/)) {
		return;
	}
	const target: HTMLElement = document.querySelector(href);
	if (!target) {
		return;
	}
	element.addEventListener('click', (event: Event) => {
		event.preventDefault();
		scrollTo(target);
		window.history.pushState(null, '', href);
	})
}

export function scrollTo(target: HTMLElement): void {
	window.scrollTo({
		top: (target.offsetTop || target.clientTop) - PAGE_TOP,
		behavior: 'smooth'
	});
	//target.scrollIntoView({behavior: 'smooth', block: 'center'});
}
