import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import Webcam from "react-webcam";

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: "user",
};

const Screenshot = (props) => {
  const { mob } = props;
  const webcamRef = React.useRef(null);
  const [image, setImage] = useState();
  const [camOpen, setCamOpen] = useState(false);

  const capture = React.useCallback(() => {
    setImage(webcamRef.current.getScreenshot());

    setCamOpen(!camOpen);
  }, [webcamRef, image, camOpen]);

  useEffect(() => {
    if (camOpen) {
      setImage("");
    }
  }, [image]);

  return (
    <>
      <div className="screenshot">
        <div className="screenshot__wrapper">
          {camOpen && (
            <div className="video-container">
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
                // width={mob ? "475" : "1280"}
                // height={mob ? "450" : "720"}
              />
            </div>
          )}
          <button
            className="open-cam"
            onClick={(e) => {
              setCamOpen(!camOpen);
              setImage("");
            }}
          >
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.16113 21.002H16.3018C18.5605 21.002 19.8701 19.7275 19.8701 17.5127V16.458L23.0254 19.1299C23.4385 19.4814 23.8691 19.7188 24.291 19.7188C25.0908 19.7188 25.6445 19.1387 25.6445 18.2686V9.08398C25.6445 8.21387 25.0908 7.63379 24.291 7.63379C23.8691 7.63379 23.4385 7.87109 23.0254 8.21387L19.8701 10.8945V9.7959C19.8701 7.58105 18.5605 6.29785 16.3018 6.29785H7.16113C4.98145 6.29785 3.60156 7.58105 3.60156 9.7959V17.5742C3.60156 19.7803 4.90234 21.002 7.16113 21.002ZM7.45117 19.4199C6.08008 19.4199 5.29785 18.6816 5.29785 17.2578V10.0508C5.29785 8.60059 6.08008 7.88867 7.45117 7.88867H16.0117C17.3828 7.88867 18.165 8.61816 18.165 10.0508V17.2578C18.165 18.6816 17.374 19.4199 16.0117 19.4199H7.45117ZM23.6846 17.6885L19.8701 14.5684V12.7842L23.6846 9.66406C23.7637 9.60254 23.8164 9.56738 23.8867 9.56738C23.9834 9.56738 24.0361 9.6377 24.0361 9.76074V17.5918C24.0361 17.7061 23.9834 17.7852 23.8867 17.7852C23.8164 17.7852 23.7637 17.7412 23.6846 17.6885Z"
                fill="#090F11"
              />
            </svg>
            {!image && camOpen && <p>Выключить камеру</p>}
            {!image && !camOpen && <p>Примерить себя</p>}
            {image && <p>Новое фото</p>}
          </button>

          <button
            className={`photo ${camOpen ? "" : "disabled"}`}
            onClick={capture}
          >
            <svg
              width="22"
              height="24"
              viewBox="0 0 22 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11 1.39355V7.67927"
                stroke="#090F11"
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M11 16.3223V22.608"
                stroke="#090F11"
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M20.4141 6.48242L15.0456 9.63513"
                stroke="#090F11"
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M6.98438 14.3652L1.61588 17.5179"
                stroke="#090F11"
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M20.4531 17.4492L15.0602 14.3355"
                stroke="#090F11"
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M6.96094 9.66504L1.56803 6.55131"
                stroke="#090F11"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
            <p>Сфоткать</p>
          </button>
          {image && (
            <div className="screenshot__img">
              <img src={image} alt="" />
            </div>
          )}

          <div className="screenshot__mask">
            <img src="/images/Subtract.png" alt="" />
          </div>
        </div>
      </div>

      {/* <div className="screenshot-test">
        <div className="screenshot__wrapper">
          {camOpen && (
            <Webcam
              audio={false}
              height={720}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              width={1280}
              videoConstraints={videoConstraints}
            />
          )}
          <button
            className="open-cam"
            onClick={(e) => {
              setCamOpen(!camOpen);
              setImage("");
            }}
          >
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.16113 21.002H16.3018C18.5605 21.002 19.8701 19.7275 19.8701 17.5127V16.458L23.0254 19.1299C23.4385 19.4814 23.8691 19.7188 24.291 19.7188C25.0908 19.7188 25.6445 19.1387 25.6445 18.2686V9.08398C25.6445 8.21387 25.0908 7.63379 24.291 7.63379C23.8691 7.63379 23.4385 7.87109 23.0254 8.21387L19.8701 10.8945V9.7959C19.8701 7.58105 18.5605 6.29785 16.3018 6.29785H7.16113C4.98145 6.29785 3.60156 7.58105 3.60156 9.7959V17.5742C3.60156 19.7803 4.90234 21.002 7.16113 21.002ZM7.45117 19.4199C6.08008 19.4199 5.29785 18.6816 5.29785 17.2578V10.0508C5.29785 8.60059 6.08008 7.88867 7.45117 7.88867H16.0117C17.3828 7.88867 18.165 8.61816 18.165 10.0508V17.2578C18.165 18.6816 17.374 19.4199 16.0117 19.4199H7.45117ZM23.6846 17.6885L19.8701 14.5684V12.7842L23.6846 9.66406C23.7637 9.60254 23.8164 9.56738 23.8867 9.56738C23.9834 9.56738 24.0361 9.6377 24.0361 9.76074V17.5918C24.0361 17.7061 23.9834 17.7852 23.8867 17.7852C23.8164 17.7852 23.7637 17.7412 23.6846 17.6885Z"
                fill="#090F11"
              />
            </svg>
            {!image && camOpen && <p>Выключить камеру</p>}
            {!image && !camOpen && <p>Примерить себя</p>}
            {image && <p>Новое фото</p>}
          </button>

          <button
            className={`photo ${camOpen ? "" : "disabled"}`}
            onClick={capture}
          >
            <svg
              width="22"
              height="24"
              viewBox="0 0 22 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11 1.39355V7.67927"
                stroke="#090F11"
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M11 16.3223V22.608"
                stroke="#090F11"
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M20.4141 6.48242L15.0456 9.63513"
                stroke="#090F11"
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M6.98438 14.3652L1.61588 17.5179"
                stroke="#090F11"
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M20.4531 17.4492L15.0602 14.3355"
                stroke="#090F11"
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M6.96094 9.66504L1.56803 6.55131"
                stroke="#090F11"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
            <p>Сфоткать</p>
          </button>

          <div className="screenshot__img">
            <img src={image} alt="" />
          </div>

          <div className="screenshot__mask">
            <img src="/images/Subtract.png" alt="" />
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Screenshot;
